import request from '@/utils/request'

const as = require('qs')

function apiAxios(method, url, params) {
return request({
method: method,
url: url,
data: method === 'POST' || method === 'PUT' || method === 'DELETE' ? params : null,
params: method === 'GET' ? params : null,
paramsSerializer: method === 'GET' ? function(params) {
return as.stringify(params, { arrayFormat: 'repeat' })
} : null
})
}
export default {
get: function(vue, path, params) {
return apiAxios('GET', path, params)
},
post: function(vue, path, params) {
return apiAxios('POST', path, params)
},
put: function(vue, path, params) {
return apiAxios('PUT', path, params)
},
delete: function(vue, path, params) {
return apiAxios('DELETE', path, params)
},
exportData: function(vue, path, params) {
return exportData('POST', path, params)
},
getData: function(vue, path, params) {
return getData('GET', path, params)
},
putCode: function(vue, path, params) {
return putCode('PUT', path, params)
}
}

export function exportData(method, url, params) {
return request({
url: url,
method: method,
data: params,
responseType: 'arraybuffer',
timeout: 3600000
})
}

export function getData(method, url, params) {
return request({
url: url,
method: method,
data: params,
responseType: 'arraybuffer',
timeout: 3600000 
})
}

export function putCode(method, url, params) {
return request({
url: url,
method: method,
data: params,
timeout: 3600000 
})
}
