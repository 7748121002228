<template>
  <el-container style="height: 100vh;">
    <div class="_out_slide_wmskl_2">
      <ul class="_slide_list_wmskl_17">
        <li v-for="(item,index ) in moduleJson" :key="index" @click="selectItem(index)" :class="selectIndex==index?'_slide_item_wmskl_25 _slide-item-active_wmskl_42':'_slide_item_wmskl_25'">
          <span v-if="item.abbreviation=='设备'" class="anticon"><svg viewBox="0 0 24 24" width="14" height="14" focusable="false" fill="currentColor" aria-hidden="true">
              <path d="M14.5,6 C17.2614237,6 19.5,8.23857625 19.5,11 C19.5,12.5000624 18.8394215,13.8458384 17.7932929,14.7622997 C20.1367027,15.9075124 21.8009709,18.2419626 21.983436,20.9981172 C22.0199189,21.5491956 21.6027569,22.0255082 21.0516784,22.0619912 C20.5006,22.0984741 20.0242874,21.681312 19.9878045,21.1302336 C19.7972842,18.2524047 17.4000926,16 14.5,16 C11.6180849,16 9.23007699,18.2251329 9.01564942,21.0813365 C8.97430337,21.6320715 8.49432691,22.0450127 7.94359199,22.0036667 C7.39285707,21.9623206 6.97991581,21.4823442 7.02126186,20.9316092 C7.22610269,18.2031022 8.88289963,15.8980165 11.206636,14.7605307 C10.160308,13.8452873 9.5,12.4997552 9.5,11 C9.5,8.23857625 11.7385763,6 14.5,6 Z M9.5,2 C11.1647837,2 12.6395363,2.81361958 13.5483141,4.06491498 C12.7975778,4.16634654 12.0859119,4.38695651 11.4314932,4.70663451 C10.9111471,4.26545236 10.2365734,4 9.5,4 C7.84314575,4 6.5,5.34314575 6.5,7 C6.5,7.97554456 6.96563836,8.84233345 7.68678497,9.39023656 C7.56444451,9.90630066 7.5,10.4456244 7.5,11 C7.5,11.4548227 7.54337726,11.8995142 7.62622048,12.330163 C5.90050475,12.9532474 4.55755172,14.4221633 4.13654058,16.2756778 C4.01420954,16.814244 3.47844645,17.1516692 2.93988025,17.0293382 C2.40131404,16.9070071 2.06388877,16.3712441 2.18621982,15.8326779 C2.69909557,13.5747265 4.21275892,11.73628 6.20711202,10.7607303 C5.16057846,9.84583836 4.5,8.50006236 4.5,7 C4.5,4.23857625 6.73857625,2 9.5,2 Z M14.5,8 C12.8431458,8 11.5,9.34314575 11.5,11 C11.5,12.6568542 12.8431458,14 14.5,14 C16.1568542,14 17.5,12.6568542 17.5,11 C17.5,9.34314575 16.1568542,8 14.5,8 Z" fill-rule="evenodd"></path>
            </svg></span>
          <span v-else-if="item.abbreviation=='定制'" class="anticon"><svg viewBox="0 0 24 24" width="14" height="14" focusable="false" fill="currentColor" aria-hidden="true">
              <path d="M13.0392305,2.27650426 L19.9607695,6.2445763 C20.6038476,6.61324865 21,7.29458326 21,8.03192796 L21,15.968072 C21,16.7054167 20.6038476,17.3867513 19.9607695,17.7554237 L13.0392305,21.7234957 C12.3961524,22.0921681 11.6038476,22.0921681 10.9607695,21.7234957 L4.03923048,17.7554237 C3.39615242,17.3867513 3,16.7054167 3,15.968072 L3,8.03192796 C3,7.29458326 3.39615242,6.61324865 4.03923048,6.2445763 L10.9607695,2.27650426 C11.6038476,1.90783191 12.3961524,1.90783191 13.0392305,2.27650426 Z M5.078,9.372 L5.07846097,15.968072 L10.96,19.34 L10.96,12.746 L5.078,9.372 Z M18.921,9.373 L17.196,10.362 L17.1961524,13.0122595 C17.1961524,13.5645443 16.7484372,14.0122595 16.1961524,14.0122595 L16.1176915,14.0122595 C15.5654067,14.0122595 15.1176915,13.5645443 15.1176915,13.0122595 L15.117,11.554 L13.039,12.747 L13.039,19.34 L18.921539,15.968072 L18.921,9.373 Z M12,4.06385592 L5.986,7.51 L12,10.96 L18.013,7.511 L12,4.06385592 Z" fill-rule="evenodd"></path>
            </svg></span>
          <span v-else class="anticon"><svg viewBox="0 0 24 24" width="14" height="14" focusable="false" fill="currentColor" aria-hidden="true">
              <path d="M5,12 C6.1045695,12 7,12.8954305 7,14 L7,20 C7,21.1045695 6.1045695,22 5,22 L4,22 C2.8954305,22 2,21.1045695 2,20 L2,14 C2,12.8954305 2.8954305,12 4,12 L5,12 Z M5,14 L4,14 L4,20 L5,20 L5,14 Z M12,7 C13.1045695,7 14,7.8954305 14,9 L14,20 C14,21.1045695 13.1045695,22 12,22 L11,22 C9.8954305,22 9,21.1045695 9,20 L9,9 C9,7.8954305 9.8954305,7 11,7 L12,7 Z M12,9 L11,9 L11,20 L12,20 L12,9 Z M19,2 C20.1045695,2 21,2.8954305 21,4 L21,20 C21,21.1045695 20.1045695,22 19,22 L18,22 C16.8954305,22 16,21.1045695 16,20 L16,4 C16,2.8954305 16.8954305,2 18,2 L19,2 Z M19,4 L18,4 L18,20 L19,20 L19,4 Z" fill-rule="evenodd"></path>
            </svg></span>
          <span class="_item-title_wmskl_46">{{item.abbreviation}}</span>
        </li>
      </ul>
    </div>
    <el-aside :width="asideWidth" style="height: 100vh;">
      <AsidePanel :selectIndex="selectIndex">
      </AsidePanel>
    </el-aside>
    <el-container style="height: 100vh;background-color: #f0f2f5">
      <el-header>
        <span class="anticon _icon_5a4wy_8"></span>
        <div class="_right_content_5a4wy_13">
          <div class="_right_11ae4_11">
            <div class="_rightContent_11ae4_30"><span style="color: rgba(0, 0, 0, 0.85); cursor: pointer;">返回商城</span>
              <div class="ant_space ant_space_align_center" style="cursor: pointer; padding: 0px 6px; margin-left: 10px; gap: 5px;">
                <div>捷客特商家</div>
              </div>
              <div class="ant_space ant_space_align_center selectLangBox" style="cursor: pointer; display: flex; align-items: center; gap: 5px;">
                <div>
                  <img src="http://lingxi-frontend-test.oss-cn-hangzhou.aliyuncs.com/images/china.png" style="width: 24px; height: 17px;">
                </div>
                <div><span>简体中文</span></div>
              </div>
              <span class="_action_11ae4_35 _account_11ae4_63">
                <span role="img" class="anticon">
                  <svg width="56px" height="56px" viewBox="0 0 56 56" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" class="_logo_11ae4_70">
                    <defs>
                      <circle id="path-1" cx="28" cy="28" r="28"></circle>
                    </defs>
                    <g id="\u6211" stroke="none" stroke-width="1" fill="none" fill-rule="evenodd">
                      <g>
                        <mask id="mask-2" fill="white">
                          <use xlink:href="#path-1"></use>
                        </mask>
                        <use id="Mask" fill="#E6E7EB" xlink:href="#path-1"></use>
                        <path d="M33.6555679,41.9707886 C33.6555679,39.4830014 35.5358268,40.29543 36.3612399,35.7393901 C36.7035576,33.8493771 38.3642448,35.7089168 38.6821448,31.3941714 C38.6821448,29.6745403 37.7757776,29.2472439 37.7757776,29.2472439 C37.7757776,29.2472439 38.2364274,26.702535 38.4170246,24.7441009 C38.640259,22.3042277 37.0383622,16 28.4925336,16 C19.9463292,16 18.3434933,22.3042277 18.5680425,24.7441009 C18.7482641,26.702535 19.2089139,29.2472439 19.2089139,29.2472439 C19.2089139,29.2472439 18.3025467,29.6745403 18.3025467,31.3941714 C18.6196954,35.7089168 20.2801947,33.8493771 20.6227003,35.7393901 C21.4491464,40.2953342 23.3279966,39.4830014 23.3279966,41.9707886 C23.3279966,46.1150562 21.3612426,48.0505874 15.2037493,50.3452814 C9.02681572,52.6468751 4,55.4864395 4,57.0869565 L4,61 L52.9999992,61 C52.9999992,61 53,58.6864194 53,57.0869565 C53,55.4864395 47.9580635,52.6468751 41.78113,50.3452814 C35.6234488,48.0505874 33.6555679,46.1150562 33.6555679,41.9707886 Z" fill="#FAFAFA" mask="url(#mask-2)">
                        </path>
                      </g>
                    </g>
                  </svg>
                </span>
                <span class="_name_11ae4_101">范忠磊</span>
              </span>
            </div>
          </div>
        </div>
      </el-header>
      <el-main style="padding: 0px;">
        <router-view />
      </el-main>
    </el-container>
  </el-container>
</template>
<script>
import AsidePanel from '../../components/column/AsidePanel.vue';
import moduleJson from '../../components/column/module.json';
const options = {
  name: 'index',
  components: { AsidePanel },
  data() {
    return {
      selectIndex: localStorage.getItem('selectIndex'),
      isCollapse: true,
      asideWidth: '200px',
      icon: 'el-icon-s-fold',
      moduleJson: moduleJson
    }
  },
  created() {

  },
  methods: {
    selectItem(index) {
      this.selectIndex = index;
      localStorage.setItem('selectIndex', index);
    }
  }

}
export default options;
</script>
<style lang="scss" scoped>
.el-header {
  height: 49px !important;
  background-color: #ffffff !important;
  border-bottom: 1px solid #f5f6f7;
  border-left: 1px solid #f5f6f7;
  line-height: 49px !important;
  display: flex;
  justify-content: space-between;

  .anticon {
    display: inline-block;
    color: inherit;
    font-style: normal;
    line-height: 0;
    text-align: center;
    text-transform: none;
    vertical-align: -0.125em;
    text-rendering: optimizelegibility;
    -webkit-font-smoothing: antialiased;
  }

  ._right_11ae4_11 ._account_11ae4_63 ._logo_11ae4_70 {
    width: 24px;
    height: 24px;
    margin-right: 8px;
  }

  ._right_11ae4_11 ._action_11ae4_35 {
    display: flex;
    align-items: center;
    justify-content: center;
    height: 100%;
    padding: 0 8px;
    cursor: pointer;
    transition: all 0.3s;
  }

  .selectLangBox {
    margin-left: 10px;
  }

  img {
    vertical-align: middle;
    border-style: none;
  }

  ._right_content_5a4wy_13 {
    height: 100%;
    display: flex;
    align-items: center;
    cursor: pointer;

    ._right_11ae4_11 {
      display: flex;
      flex-direction: row;
      align-items: center;
      width: 100%;
      height: 64px;
      margin-left: auto;
      font-size: 12px;
      position: relative;
      justify-content: space-between;
      overflow: hidden;

      .ant_space {
        display: inline-flex;
      }

      ._rightContent_11ae4_30 {
        display: flex;
        flex-direction: row;
        align-items: center;
      }

      .ant_space_align_center {
        align-items: center;
      }
    }
  }
}

// ._slide_item_wmskl_25:hover {
//   background-color: #ffffff14;
//   color: #fff;
// }

._slide-item-active_wmskl_42 {
  background-color: #ffffff14;
  color: #fff;
}

._item-title_wmskl_46 {
  margin-top: 4px;
  font-size: 12px;
}

._out_slide_wmskl_2 {
  // width: 64px;
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 12px 10px;
  background-color: #323a44;
}

._slide_list_wmskl_17 {
  padding: 24px 0;
  flex: 1;
  overflow-x: auto;
  color: #91959b;
}

._slide_item_wmskl_25 {
  width: 48px;
  height: 48px;
  margin-bottom: 4px;
  text-align: center;
  border-radius: 8px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;

  cursor: pointer;
}

.el-header {
  background-color: #b3c0d1;
  color: #333;
  line-height: 60px;
  background-color: #409eff;
}

.el-aside {
  color: #333;
}
</style>
