<template>
  <div>
    <div class="page_header_title_box">定制订单列表</div>
    <div style="padding: 70px 20px 20px 20px;">
      <div class="content_box">
        <div class="heading_box">
          <div class="search_for_box">

          </div>
          <div class="search_for_box">
            <el-input placeholder="请输入订单号" v-model="pageInfo.orderCode" @keyup.enter.native="onSearch()">
              <el-button slot="append" icon="el-icon-search" @click.prevent="onSearch()"></el-button>
            </el-input>
          </div>
        </div>
        <el-table :data="orderList" v-loading="loading">
          <el-table-column label="id" align="center" prop="id" width="60"></el-table-column>
          <el-table-column label="订单号" align="center" prop="orderCode"></el-table-column>
          <el-table-column label="下单时间" align="center" prop="createTime"></el-table-column>
          <el-table-column label="收货人" align="center">
            <template slot-scope="scope">
              {{scope.row.userAddress!=undefined?scope.row.userAddress.userName:'-'}}
            </template>
          </el-table-column>
          <el-table-column label="订单金额" align="center" prop="totalAmount" width="80"></el-table-column>
          <el-table-column label="支付状态" align="center" prop="paymentStatus" width="80">
            <template slot-scope="scope">
              {{scope.row.paymentStatus==1?'未支付':'已支付'}}
            </template>
          </el-table-column>
          <el-table-column label="支付方式" align="center" prop="paymentStatus" width="80">
            <template>
              微信支付
            </template>
          </el-table-column>
          <el-table-column label="订单状态" align="center" prop="orderStatus" width="80">
            <template slot-scope="scope">
              {{orderStatus(scope.row.orderStatus)}}
            </template>
          </el-table-column>
          <el-table-column label="操作" align="center">
            <!-- case 1:
          return "待付款";
        case 2:
          return "待发货";
        case 3:
          return "待收货";
        case 4:
          return "已关闭";
        case 5:
          return "已完成";
        case 6:
          return "待审核";
        case 7:
          return "已拒绝"; -->
            <template slot-scope="scope">
              <el-button v-if="scope.row.orderStatus==3" type="primary" @click="handleComplete(scope.row,5)">完成</el-button>
              <el-button v-if="scope.row.orderStatus==1||scope.row.orderStatus==2" type="primary" @click="handleComplete(scope.row,4)">取消</el-button>
              <el-button v-if="scope.row.orderStatus==2" type="danger" @click="sendOutGoods(scope.row)">发货</el-button>
              <el-button type="warning" @click="goOrderDetails(scope.row.id)">详情</el-button>
            </template>
          </el-table-column>
        </el-table>
        <el-pagination :total="total" :page="pageInfo.pageNo" @size-change="onSizeCHange" @current-change="onCurrentChange">
        </el-pagination>
      </div>
    </div>
    <el-dialog title="发货" :visible="modifylogisticsShow" :before-close="closeDialog" width="500px">
      <el-form class="demo-form-inline">
        <el-form-item label="物流公司名称" label-width="120px" :required="true">
          <el-select size="mini" v-model="shipperCode" placeholder="请选择" @change="selectOption(logisticsId)">
            <el-option v-for="item in expressData" :key="item.shipperCode" :label="item.name" :value="item.shipperCode"></el-option>
          </el-select>
        </el-form-item>
        <el-form-item label="快递单号" :required="true" label-width="120px">
          <el-input v-model="logisticCode" size="mini" placeholder="请填写快递单号"></el-input>
        </el-form-item>
      </el-form>
      <div style="text-align: right;">
        <el-button @click="closeDialog()">取消</el-button>
        <el-button type="primary" @click="submitTo()">提交</el-button>
      </div>
    </el-dialog>
  </div>
</template>
<script>
import api from "@/api/allUrl";
import axios from 'axios';
export default {
  name: "diyOrderIndex",
  data() {
    return {
      pageInfo: {
        orderCode: undefined,
        pageNo: 1,
        pageSize: 12,
      },
      orderList: [],
      total: 1,
      orderId: '',
      loading: false,
      modifylogisticsShow: false,
      expressData: [
        {
          name: '中通快递',
          shipperCode: 'ZTO'
        }
      ],
      shipperCode: 'ZTO',
      logisticCode: ''
    }
  },
  created() {
    this.loading = true;
    this.getDiyOrderList();
  },
  methods: {
    handleComplete(item, orderStatus) {
      var message = "是否确认完成？";
      if (orderStatus == 4) {
        message = "是否确认关闭该订单？";
      }
      this.$confirm(message, "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning",
        center: true
      }).then(() => {

        const service = axios.create({
          baseURL: '/samsung_api',
          timeout: 60000,
          headers: {
            'Accept': 'application/json',
            'Content-Type': 'application/json',
            'Platform': 'GF60004',
            'Version': '1.0.0',
            'token': '',
            'Cache-Control': 'no-cache',
            'Pragma': 'no-cache',
            'tenantNo': 100,
            'value': 100,
          },
          withCredentials: false
        })
        var payload = {
          orderId: item.id,
          orderStatus: orderStatus
        }
        service({
          method: 'post',
          url: 'https://api.jakit.shop' + api.modifyOrderStatus,
          data: payload,
          header: {
            'Content-Type': 'application/json',
          },
        }).then(() => {
          this.$message.success({
            message: "操作成功",
            duration: 3 * 1000,
          });
          this.pageInfo.pageNo += 1;
          this.loading = true;
          this.getDiyOrderList();
        });

        // this.$api.post(this, api.modifyOrderStatus, {
        //   orderId: item.id,
        //   orderStatus: orderStatus
        // }).then(res => {
        //   if (res.success) {
        //     this.$message.success({
        //       message: "操作成功",
        //       duration: 3 * 1000,
        //     });
        //     this.pageInfo.pageNo += 1;
        //     this.loading = true;
        //     this.getDiyOrderList();
        //   }
        // })
      }).catch(res => {
        console.log(res);
        this.$message({
          type: "info",
          message: "已取消删除"
        });
      });
    },
    goOrderDetails(id) {
      var query = { id: id, };
      this.$router.push({
        name: "diyOrderdetails",
        query: query,
      });
    },
    submitTo() {
      if (this.logisticCode == "") {
        this.$message({
          message: '请填写物流单号',
          type: 'error',
          duration: 3 * 1000,
          onClose: () => {
          }
        })
      } else if (this.shipperCode == '') {
        this.$message({
          message: '请选择物流方式',
          type: 'error',
          duration: 3 * 1000,
          onClose: () => {
          }
        })
      } else {
        const service = axios.create({
          baseURL: '/samsung_api',
          timeout: 60000,
          headers: {
            'Accept': 'application/json',
            'Content-Type': 'application/json',
            'Platform': 'GF60004',
            'Version': '1.0.0',
            'token': '',
            'Cache-Control': 'no-cache',
            'Pragma': 'no-cache',
            'tenantNo': 100,
            'value': 100,
          },
          withCredentials: false
        })
        service({
          method: 'post',
          url: 'https://api.jakit.shop' + api.sendOutGoods,
          data: {
            logisticCode: this.logisticCode,
            orderId: this.orderId,
            shipperCode: this.shipperCode
          },
          header: {
            'Content-Type': 'application/json',
          },
        }).then(() => {
          this.$message.success({
            message: "发货成功",
            duration: 3 * 1000,
          })
          this.pageInfo.pageNo = 1;
          this.logisticCode = '';
          this.modifylogisticsShow = false;
          this.getDiyOrderList();
        });



        // this.$api.post(this, api.sendOutGoods, {
        //   logisticCode: this.logisticCode,
        //   orderId: this.orderId,
        //   shipperCode: this.shipperCode
        // }).then(res => {
        //   if (res.success) {
        //     this.$message.success({
        //       message: "发货成功",
        //       duration: 3 * 1000,
        //     })
        //     this.pageInfo.pageNo = 1;
        //     this.logisticCode = '';
        //     this.modifylogisticsShow = false;
        //     this.getDiyOrderList();
        //   }
        // })
      }
    },
    closeDialog() {
      this.modifylogisticsShow = false;
    },
    sendOutGoods(item) {
      this.modifylogisticsShow = true;
      this.orderId = item.id;
    },
    onSearch() {
      this.loading = true;
      this.pageInfo.pageNo = 1;
      this.getDiyOrderList();
    },
    getDiyOrderList() {
      this.pageInfo.pageNo -= 1;
      const service = axios.create({
        baseURL: '/samsung_api',
        timeout: 60000,
        headers: {
          'Accept': 'application/json',
          'Content-Type': 'application/json',
          'Platform': 'GF60004',
          'Version': '1.0.0',
          'token': '',
          'Cache-Control': 'no-cache',
          'Pragma': 'no-cache',
          'tenantNo': 100,
          'value': 100,
        },
        withCredentials: false
      })
      var that = this;
      service({
        method: 'post',
        url: 'https://api.jakit.shop' + api.getDiyOrderList,
        data: this.pageInfo,
        header: {
          'Content-Type': 'application/json',
        },
      }).then(res => {
        that.orderList = res.data.data.content;
        that.total = res.data.data.totalElements;
        that.loading = false;
      });


      // this.pageInfo.pageNo -= 1;
      // this.$api.post(this, api.getDiyOrderList, this.pageInfo).then(res => {
      //   this.orderList = res.data.content;
      //   this.total = res.data.totalElements;
      //   this.loading = false;
      // })
    },
    onSizeCHange(val) {
      // 分页方法
      this.loading = true;
      this.pageInfo.pageSize = val;
      this.pageInfo.pageNo = 1;
      this.getDiyOrderList();
    },
    onCurrentChange(val) {
      // 分页方法
      this.loading = true;
      this.pageInfo.pageNo = val;
      this.getDiyOrderList();
    },
    // 订单状态
    orderStatus(val) {
      switch (val) {
        case 1:
          return "待付款";
        case 2:
          return "待发货";
        case 3:
          return "待收货";
        case 4:
          return "已关闭";
        case 5:
          return "已完成";
        case 6:
          return "待审核";
        case 7:
          return "已拒绝";
      }
    },
  }
};
</script>

<style rel="stylesheet/scss" lang="scss" scoped>
</style>
