<template>
  <div>
    <div class="page_header_title_box">
      膜品类型
    </div>
    <div style="padding: 70px 20px 20px 20px;">
      <div class="content_box">
        <div class="heading_box">
          <div class="search_for_box">
            <el-button class="mini_add_btn" @click="addpuls(1)">+ 添加膜品类型</el-button>
          </div>
          <div class="search_for_box">
            <el-select v-model="pageInfo.contentType" size="mini" placeholder="请选择" style="width: 180px;margin-right: 5px;" clearable>
              <el-option label="膜品类型ID" :value="1"></el-option>
              <el-option label="膜品类型名称" :value="2"></el-option>
              <el-option label="关联货品编码" :value="3"></el-option>
            </el-select>
            <el-input placeholder="请输入内容" v-model="pageInfo.content" @keyup.enter.native="onSearch()">
              <el-button slot="append" icon="el-icon-search" @click.prevent="onSearch()"></el-button>
            </el-input>
          </div>
        </div>
        <el-table :data="tableData" header-row-class-name="header-row" class="tableCenter" v-loading="loading">
          <el-table-column label="膜品类型ID" align="center" prop="id"></el-table-column>
          <el-table-column label="膜码类型名称" align="center" prop="name"></el-table-column>
          <el-table-column label="切膜数次" align="center" prop="count"></el-table-column>
          <el-table-column label="赠送次数" align="center" prop="reCount"></el-table-column>
          <el-table-column label="描述" align="center" prop="remark"></el-table-column>
          <el-table-column label="操作" align="center">
            <template slot-scope="scope">
              <el-button @click="addpuls(3, scope.row.id)">编辑
              </el-button>
              <el-button type="danger" @click="handleDelete(scope.row)">删除
              </el-button>
            </template>
          </el-table-column>
        </el-table>
        <el-pagination :total="total" :page="pageInfo.page" @size-change="onSizeCHange" @current-change="onCurrentChange">
        </el-pagination>
      </div>
    </div>

  </div>
</template>
<script>
import api from "@/api/allUrl";
export default {
  name: "filmType",
  data() {
    return {
      total: 1,
      pageInfo: {
        page: 1,
        size: 10,
        content: '',
        contentType: undefined
      },
      loading: false,
      tableData: []
    }
  },
  created() {
    this.loading = true;
    this.tableData = [];
    this.getFilmtypeList();
  },
  methods: {
    //删除设备
    handleDelete(row) {
      this.$confirm("确定删除该膜品类型？", "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning",
        center: true
      }).then(res => {
        console.log(res);
        this.$api.delete(this, api.deleteFilmtypeDetail, { id: row.id }).then(res => {
          if (res.success) {
            this.$message.success({
              message: "删除成功",
              duration: 3 * 1000,
            });
            this.pageInfo.page = 1;
            this.pageInfo.content = '';
            this.loading = true;
            this.getFilmtypeList();
          }
        })
      }).catch(res => {
        console.log(res);
        this.$message({
          type: "info",
          message: "已取消删除"
        });
      });
    },
    getFilmtypeList() {
      this.$api.get(this, api.getFilmtypeList, this.pageInfo).then(res => {
        if (res.success) {
          this.tableData = res.data.list;
          this.total = res.data.total;
        }
        this.loading = false;
      })
    },
    onSearch() {
      this.pageInfo.page = 1;
      this.loading = true;
      this.getFilmtypeList();
    },
    addpuls(checkMsg, id) {
      var query = {};
      if (id) {
        query = {
          checkMsg: checkMsg,
          id: id,
        };
      } else {
        query = {
          checkMsg: checkMsg,
        };
      }
      this.$router.push({
        name: "add_filmtype",
        query: query,
      });
    },
    onSizeCHange(val) {
      // 分页方法
      this.loading = true;
      this.pageInfo.size = val;
      this.pageInfo.page = 1;
      this.getFilmtypeList();
    },
    onCurrentChange(val) {
      // 分页方法
      this.loading = true;
      this.pageInfo.page = val;
      this.getFilmtypeList();
    },
  }
};
</script>

<style rel="stylesheet/scss" lang="scss" scoped>
</style>
