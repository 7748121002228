import Vue from 'vue'
import App from './App.vue'
import router from './router'
import VueRouter from 'vue-router'
import Element from 'element-ui'
import 'element-ui/lib/theme-chalk/index.css'
import './assets/global.css'
import axios from 'axios'
import echarts from 'echarts'
import '/src/styles/index.css'

import api from './api/api'

Vue.prototype.$echarts = echarts;
Vue.prototype.$api = api;
Vue.prototype.$BASE_API = "https://api.jakit.shop";
Vue.use(Element,{size:'small'})
Vue.use(VueRouter)

Vue.config.productionTip = false

new Vue({
  router,
  render: h => h(App)
}).$mount('#app')

const service=axios.create({
  baseURL: '',
  timeout: 5000
});


export default service;
