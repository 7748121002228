<template>
  <div id="app">
    <router-view />
  </div>
</template>

<style></style>
<script src="https://gosspublic.alicdn.com/aliyun-oss-sdk-4.4.4.min.js"></script>
<script>
export default {
  name: 'app',
}
</script>
