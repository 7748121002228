
// 去重
export function setArr2(arr) {
  const obj = {}
  const temp = []
  for (let i = 0; i < arr.length; i++) {
    const type = Object.prototype.toString.call(arr[i])// 不加类型 分不清 1 '1'
    if (!obj[ arr[i] + type]) {
      temp.push(arr[i])
      obj[ arr[i] + type ] = true// 这里给true 利于代码阅读和判断。  如果给 0,'' ,false ,undefined 都会在if那里判断为 false 不利于代码阅读
    }
  }
  return temp
}
export function parseTime(time, cFormat) {
  if (time === 0) {
    return '-'
  }
  const format = cFormat || '{y}-{m}-{d} {h}:{i}:{s}'
  let date
  if (typeof time === 'object') {
    date = time
  } else {
    if (('' + time).length === 10) time = parseInt(time) * 1000
    date = new Date(time)
  }
  const formatObj = {
    y: date.getFullYear(),
    m: date.getMonth() + 1,
    d: date.getDate(),
    h: date.getHours(),
    i: date.getMinutes(),
    s: date.getSeconds(),
    a: date.getDay()
  }
  const time_str = format.replace(/{(y|m|d|h|i|s|a)+}/g, (result, key) => {
    let value = formatObj[key]
    if (key === 'a') return ['一', '二', '三', '四', '五', '六', '日'][value - 1]
    if (result.length > 0 && value < 10) {
      value = '0' + value
    }
    return value || 0
  })
  return time_str
}
export function monthDay(data) { // 返回年月日
  if (data) {
    const date = new Date(data)
    const year = date.getFullYear()
    const month = (date.getMonth() + 1) > 9 ? date.getMonth() + 1 : '0' + (date.getMonth() + 1)
    const day = date.getDate() > 9 ? date.getDate() : '0' + date.getDate()
    return year + '-' + month + '-' + day + ''
  }
}