<template>
  <div>
    <div class="page_header_title_box">优惠券列表</div>
    <div style="padding: 70px 20px 20px 20px;">
      <div class="content_box">
        <div class="heading_box">
          <div class="search_for_box">
            <el-button class="mini_add_btn" @click="handleCoupon(1)">+ 添加优惠券</el-button>
          </div>
          <div class="search_for_box">
            <el-select size="mini" v-model="pageInfo.couponCategory" placeholder="优惠券类型" style="width: 180px;margin-right: 5px" @change="onSearch()" clearable>
              <el-option label="定制商品券" :value="1"></el-option>
              <el-option label="服务券" :value="2"></el-option>
            </el-select>
            <el-select size="mini" v-model="pageInfo.couponMethod" placeholder="优惠形式" style="width: 160px;margin-right: 5px" @change="onSearch()" clearable>
              <el-option label="满减" value="1"></el-option>
              <el-option label="满折" value="2"></el-option>
              <el-option label="兑换" value="3"></el-option>
            </el-select>
            <el-select size="mini" v-model="pageInfo.couponStatus" placeholder="状态" style="width: 160px;margin-right: 5px" @change="onSearch()" clearable>
              <el-option label="未发布" :value="0"></el-option>
              <el-option label="未开始" :value="1"></el-option>
              <el-option label="进行中" :value="2"></el-option>
              <el-option label="已过期" :value="3"></el-option>
              <el-option label="提前结束" :value="4"></el-option>
              <el-option label="已作废" :value="5"></el-option>
            </el-select>
            <el-input placeholder="请输入优惠券名称" v-model.trim="pageInfo.content" @keyup.enter.native="onSearch()">
              <el-button slot="append" icon="el-icon-search" @click.prevent="onSearch()"></el-button>
            </el-input>
          </div>
        </div>
        <el-table :data="tableData" header-row-class-name="header-row">
          <el-table-column label="优惠券名称" fixed="left" show-overflow-tooltip="" align="left" prop="name" width="120"></el-table-column>
          <el-table-column label="优惠券类型" align="center" width="130">
            <template slot-scope="scope">
              <span v-if="scope.row.couponCategory == 1">定制商品券</span>
              <span v-else-if="scope.row.couponCategory == 2">服务券</span>
              <span v-else>--</span>
            </template>
          </el-table-column>
          <el-table-column label="优惠形式" align="center" width="90">
            <template slot-scope="scope">
              <span v-if="scope.row.couponMethod === 1">满减</span>
              <span v-else-if="scope.row.couponMethod === 2">满折</span>
              <span v-else-if="scope.row.couponMethod === 3">兑换</span>
              <span v-else>--</span>
            </template>
          </el-table-column>
          <el-table-column label="已领取/总数量" align="center" prop="codeCount" width="130"></el-table-column>
          <el-table-column label="申请状态" align="center" width="90">
            <template slot-scope="scope">
              <span v-if="scope.row.applyStatus === 0">草稿</span>
              <span v-else-if="scope.row.applyStatus === 1">申请中</span>
              <span v-else-if="scope.row.applyStatus === 2">申请通过</span>
              <span v-else-if="scope.row.applyStatus === 3">申请失败</span>
              <span v-else>--</span>
            </template>
          </el-table-column>
          <el-table-column label="状态" align="center" width="90">
            <template slot-scope="scope">
              <span v-if="scope.row.couponStatus === 0">未发布</span>
              <span v-else-if="scope.row.couponStatus === 1">未开始</span>
              <span v-else-if="scope.row.couponStatus === 2">进行中</span>
              <span v-else-if="scope.row.couponStatus === 3">已过期</span>
              <span v-else-if="scope.row.couponStatus === 4">提前结束</span>
              <span v-else-if="scope.row.couponStatus === 5">已作废</span>
              <span v-else>--</span>
            </template>
          </el-table-column>
          <el-table-column label="领券时间" align="center" prop="validTime" width="300" show-overflow-tooltip></el-table-column>
          <el-table-column label="作废说明" align="center" prop="invalidExplain" :min-width="120" show-overflow-tooltip></el-table-column>
          <el-table-column label="操作" fixed="right" width="450" align="center">
            <template slot-scope="scope">
              <el-button type="primary" v-if="scope.row.couponStatus != 0" @click="handleCode(scope.$index, scope.row.id)">码库</el-button>
              <el-button type="success" v-if="scope.row.couponStatus == 2 && scope.row.receivedType == 2" @click="handleManualOperationCoupon(scope.row.id)">手工发券</el-button>
              <el-button type="warning" v-if="scope.row.couponStatus == 0" @click="handleCoupon(3, scope.row.id)">编辑</el-button>
              <el-button v-else @click="handleCoupon(2, scope.row.id)">查看</el-button>
              <el-button type="warning" v-if="scope.row.couponStatus < 3" @click="handleCouponCount(scope.$index, scope.row)">编辑数量</el-button>
              <el-button type="success" v-if="scope.row.couponStatus == 0 && (scope.row.applyStatus) > 1" @click="handleStart(scope.row.id)">发布</el-button>
              <el-button type="danger" v-if="scope.row.couponStatus == 1 || scope.row.couponStatus == 2" @click="handleStop(scope.row.id)">作废</el-button>
              <el-button type="danger" v-if="scope.row.couponStatus == 2" @click="handleEarlyStop(scope.row.id)">提前结束</el-button>
              <el-button type="danger" v-if="scope.row.couponStatus == 0" @click="handleDelete(scope.row.id)">删除</el-button>
            </template>
          </el-table-column>
        </el-table>
        <el-pagination :total="couponTotal" :page="pageInfo.page" @size-change="onSizeCHange" @current-change="onCurrentChange">
        </el-pagination>
      </div>
      <!-- 码库 -->
      <el-dialog width="70%" :visible.sync="codeShow" center>
        <div style="margin-bottom: 20px; display: flex;justify-content: space-between;">
          <div>
            <el-button @click="handleInvalidCodeMore">批量作废</el-button>
            <el-select size="mini" v-model="codeInfo.couponStatus" placeholder="券码状态" style="width: 100px;" @change="searchCode()" clearable>
              <el-option label="未开始" value="1"></el-option>
              <el-option label="进行中" value="2"></el-option>
              <el-option label="已过期" value="3"></el-option>
              <el-option label="已作废" value="5"></el-option>
              <el-option label="已使用" value="6"></el-option>
            </el-select>
          </div>
          <div style="display: flex;">
            <el-select size="mini" v-model="codeInfo.contentType" placeholder="类型" style="width: 100px;" clearable>
              <el-option label="客户名称" value="1"></el-option>
              <el-option label="优惠券码" value="2"></el-option>
              <el-option label="手机号" value="3"></el-option>
              <el-option label="客户编码" value="4"></el-option>
            </el-select>
            <el-input placeholder="请输入内容" v-model="codeInfo.content" @keyup.enter.native="searchCode()">
              <el-button slot="append" icon="el-icon-search" @click.prevent="searchCode()"></el-button>
            </el-input>
          </div>
        </div>
        <el-table :data="codeData" header-row-class-name="header-row" class="tableCenter" height="500" ref="multipleSelect" @select="select" @select-all="selectAll" @selection-change="handleSelectionChange">
          <el-table-column align="center" type="selection" width="50" :selectable="selectable"></el-table-column>
          <el-table-column label="优惠券码" show-overflow-tooltip align="center" prop="couponNo" :min-width="140"></el-table-column>
          <el-table-column label="领取时间" align="center" prop="createTime" :min-width="160"></el-table-column>
          <el-table-column label="有效时间" align="center" prop="effectiveTime" width="300" show-overflow-tooltip></el-table-column>
          <el-table-column label="手机号" align="center" prop="phone" :min-width="120"></el-table-column>
          <el-table-column label="客户名称" align="center" prop="customerName" :min-width="120"></el-table-column>
          <el-table-column label="客户编码" align="center" prop="customerNo" :min-width="120"></el-table-column>
          <el-table-column label="渠道编码" align="center" prop="platform" :min-width="100" show-overflow-tooltip></el-table-column>
          <el-table-column label="渠道ID" show-overflow-tooltip align="center" prop="openId" :min-width="120"></el-table-column>
          <el-table-column label="券码状态" align="center" :min-width="100" show-overflow-tooltip>
            <template slot-scope="scope">
              <span v-if="scope.row.couponStatus === 5">已作废</span>
              <span v-else-if="scope.row.couponStatus === 1">未开始</span>
              <span v-else-if="scope.row.couponStatus === 2">进行中</span>
              <span v-else-if="scope.row.couponStatus === 3">已过期</span>
              <span v-else-if="scope.row.couponStatus === 6">已使用</span>
              <span v-else>--</span>
            </template>
          </el-table-column>
          <el-table-column label="作废说明" align="center" prop="invalidExplain" :min-width="120"></el-table-column>
          <el-table-column label="操作" :min-width="100" align="center">
            <template slot-scope="scope">
              <el-button class="mini-search-btn" @click="handleInvalidCode([scope.row.couponNo])" v-if="scope.row.couponStatus !== 5">作废</el-button>
            </template>
          </el-table-column>
        </el-table>
        <el-pagination :total="codeTotal" :page="codeInfo.page" @size-change="codeSizeChange" @current-change="codeCurrentChange">
        </el-pagination>
      </el-dialog>
      <!-- 编辑优惠券发放总数量和限购数量 -->
      <el-dialog title="编辑" width="30%" :visible.sync="couponShow" center>
        <el-form :model="formData" label-width="100px" ref="formData2">
          <el-form-item label="优惠券总数量" prop="generateCount">
            <el-input v-model="formData.generateCount" onkeyup="value=value.replace(/[^\d]/g,'')" placeholder="请输入优惠券发放数量，默认为无限制" clearable />
          </el-form-item>
          <el-form-item label="限购数量" prop="limitCount">
            <el-input v-model="formData.limitCount" onkeyup="value=value.replace(/[^\d]/g,'')" placeholder="请输入单个用户限领的数量上限，默认为不限制" clearable />
          </el-form-item>
        </el-form>
        <div class="button_box">
          <div class="exchange_bit" @click="handleSubmit()">确定</div>
          <div class="return_bit" @click="couponShow = false">返回</div>
        </div>
      </el-dialog>

      <!-- 手工发券 -->
      <el-dialog title="手工发券" width="60%" :visible.sync="manualOperationFlag" center>
        <el-form :rules="rules" :model="couponManualOperation" ref="ruleForm" label-width="100px">
          <el-form-item label="发券分销商ID" prop="distributorId">
            <el-input style="width: 150px;" size="mini" v-model="couponManualOperation.distributorId" />
          </el-form-item>
          <el-form-item label="发券分销平台" prop="platform">
            <el-select size="mini" v-model="couponManualOperation.platform" placeholder="发券分销平台" clearable>
              <el-option v-for="item in platforms" :key="item.platformNo" :label="item.name" :value="item.platformNo"></el-option>
            </el-select>
          </el-form-item>
          <el-form-item label="发券方式" prop="phoneOrcustomerNos">
            <el-radio-group v-model="couponManualOperation.phoneOrCustomerNoType">
              <el-radio :label="1">手机号发券</el-radio>
              <el-radio :label="2">客户编码发券</el-radio>
            </el-radio-group>
            <el-input type="textarea" :autosize="{ minRows: 2, maxRows: 5 }" placeholder="请输入需要发券的客户手机号或编码，多个中间用英文逗号隔开" v-model="phoneOrcustomerNos">
            </el-input>
          </el-form-item>
        </el-form>
        <div class="button_box">
          <div class="exchange_bit" @click="handleSubmitManualOperationCoupon('ruleForm')">提交</div>
          <div class="return_bit" @click="manualOperationFlag = false">返回</div>
        </div>
      </el-dialog>
    </div>
  </div>
</template>
<script>
import api from "@/api/allUrl";
export default {
  name: "couponList",
  data() {
    return {
      loading: false,
      pageInfo: {
        // 优惠券搜索
        couponCategory: "",
        page: 1,
        size: 10,
        couponMethod: "",
        couponStatus: "",
        contentType: '',
        content: "",
      },
      couponTotal: 0, // 消费券总数
      tableData: [], // 消费券列表
      codeShow: false, // 是否显示码库
      codeData: [], // 码库列表
      codeInfo: {
        // 码库搜索
        page: 1,
        size: 10,
        couponId: "",
        platform: "",
        couponStatus: "",
      },
      codeTotal: 0, // 码库总数
      multipleSelect: [], // 已选择优惠券 码库
      curCouponId: "", // 当前查看优惠券 码库
      couponShow: false, // 是否显示优惠券数量弹框
      formData: {
        id: '',
        generateCount: '',
        limitCount: ''
      },
      platforms: [],  // 注册渠道列表
      manualOperationFlag: false,
      manualOperationLoading: false,
      couponManualOperation: { // 手工发券
        couponId: "",
        distributorId: "",
        platform: "",
        phoneOrCustomerNoType: 1,
        phoneOrcustomerNos: "",
        num: 1
      },
      phoneOrcustomerNos: "",
      phoneOrCustomerNo: 1, // 1 手机号 2 编码
      rules: {
        distributorId: [
          { required: true, message: "请输入发券分销商ID", trigger: "blur" },
        ],
        phoneOrcustomerNos: [
          { required: true, message: "请输入发券客户手机号或编码", trigger: "blur" },
        ],
      },
    }
  },
  created() {
    this.initCouponData();
  },
  methods: {
    // 发布优惠券
    handleStart(id) {
      this.$confirm("此操作将发布该优惠券，是否继续？", "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning",
        center: true,
      }).then((_) => {
        console.log(_);
        this.loading = true;
        this.$api.put(this, api.couponStatus, { id: id, couponStatus: 1 }).then((res) => {
          if (res.success) {
            this.loading = false;

            this.$message({
              type: "success",
              message: "发布成功",
            });
            this.initCouponData();
          } else {
            this.$message({
              type: "error",
              message: res.msg,
            });
          }
        });
      }).catch(() => {
        this.$message({
          type: "info",
          message: "已取消操作",
        });
      });
    },
    // 提前结束
    handleEarlyStop(id) {
      this.$confirm("此操作将提前结束该优惠券，是否继续？", "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning",
        center: true,
      }).then((_) => {
        console.log(_);
        this.loading = true;
        this.$api.put(this, api.couponStatus, { id: id, couponStatus: 4 }).then((res) => {
          if (res.success) {
            this.loading = false;
            this.$message({
              type: "success",
              message: "操作成功",
            });
            this.initCouponData();
          } else {
            this.$message({
              type: "error",
              message: res.msg,
            });
          }
        });
      }).catch((_) => {
        console.log(_);
        this.$message({
          type: "info",
          message: "已取消操作",
        });
      });
    },
    // 作废优惠券
    handleStop(id) {
      this.$prompt("请输入作废说明", "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        inputValidator(value) {
          if (value) {
            return true;
          } else {
            return false;
          }
        },
        inputErrorMessage: "请输入作废说明，最多200个字",
      }).then(({ value }) => {
        this.loading = true;
        this.$api.put(this, api.couponStatus, { id: id, couponStatus: 5, invalidExplain: value }).then((res) => {
          if (res.success) {
            this.loading = false;
            this.$message({
              type: "success",
              message: "作废成功",
            });
            this.initCouponData();
          } else {
            this.$message({
              type: "error",
              message: res.msg,
            });
          }
        });
      }).catch(() => {
        this.$message({
          type: "info",
          message: "已取消操作",
        });
      });
    },
    // 删除优惠券
    handleDelete(id) {
      this.$confirm("此操作将删除该优惠券，是否继续？", "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning",
        center: true,
      })
        .then(() => {
          this.loading = true;
          this.$api.delete(this, api.delCoupon, { id: id }).then(res => {
            if (res.success) {
              this.loading = false;
              this.$message({
                type: "success",
                message: "删除成功",
              });
              this.initCouponData();
            } else {
              this.$message({
                type: "error",
                message: res.msg,
              });
            }
          });
        }).catch(() => {
          this.$message({
            type: "info",
            message: "已取消操作",
          });
        });
    },
    // 保存优惠券发放总数量和限购数量 
    handleSubmit() {
      if (this.formData.id) {
        this.$api.put(this, api.couponCount, this.formData).then(res => {
          if (res.success) {
            this.$message({
              type: "success",
              message: "更新成功",
            });
            this.couponShow = false
            this.initCouponData();
          }
        })
      }
    },
    // 添加/查看/编辑 - 优惠券（1，添加，2，查看；3，编辑）
    handleCoupon(checkMsg, id) {
      var query = {};
      if (id) {
        query = { checkMsg: checkMsg, id: id };
      } else {
        query = { checkMsg: checkMsg };
      }
      this.$router.push({
        name: "addCoupon",
        query: query,
      });
    },
    // 优惠券发放总数量和限购数量弹框
    handleCouponCount(index, row) {
      this.formData.id = row.id
      this.formData.generateCount = row.generateCount
      this.formData.limitCount = row.limitCount
      this.couponShow = true
    },
    handleManualOperationCoupon(id) {
      this.manualOperationFlag = true
      this.couponManualOperation.couponId = id
    },
    handleSubmitManualOperationCoupon(formName) {
      this.couponManualOperation.phoneOrcustomerNos = this.phoneOrcustomerNos
      this.$refs[formName].validate((valid) => {
        if (valid) {
          this.manualOperationLoading = true
          this.couponManualOperation.phoneOrcustomerNos = this.phoneOrcustomerNos.replace(/\s*/g, "").split(',')
          this.$api.get(this, api.manualOperationCoupon, this.couponManualOperation).then(res => {
            this.manualOperationLoading = false
            if (res.success) {
              this.$message({
                type: "success",
                message: "手工发券提交成功",
              });
              this.manualOperationFlag = false
            }
          });
        }
      });
    },
    // 码库列表
    handleCode(index, id) {
      this.codeShow = true;
      this.curCouponId = id;
      this.initCodeListData(id);
    },
    // 初始化码库列表数据
    initCodeListData(id) {
      this.loading = true;
      this.codeInfo.couponId = id;
      this.$api.get(this, api.couponNoList, this.codeInfo).then(res => {
        if (res.success) {
          if (res.data.list && res.data.list.length > 0) {
            res.data.list.forEach(item => {
              item.effectiveTime = item.startTime + " - " + item.endTime;
            })
          }
          this.codeData = res.data.list
          this.codeTotal = res.data.total
        }
        res.success ? this.loading = false : this.loading = false
      });
    },
    // 搜索优惠券列表
    onSearch() {
      this.pageInfo.page = 1;
      this.initCouponData();
    },
    // 初始化优惠券列表数据
    initCouponData() {
      this.loading = true;
      this.$api.get(this, api.couponList, this.pageInfo).then(res => {
        if (res.success) {
          this.loading = false;
          this.tableData = res.data.list;
          this.couponTotal = res.data.total;
          for (var i = 0; i < res.data.list.length; i++) {
            this.tableData.forEach((item) => {
              if (item.id === res.data.list[i].id) {
                // 使用/发放数量处理
                var codeCount = 0;
                let generatedCount = res.data.list[i].generatedCount ? res.data.list[i].generatedCount : 0
                if (res.data.list[i].generateCount === null) {
                  codeCount = generatedCount + " / 0";
                } else {
                  codeCount =
                    generatedCount +
                    " / " +
                    res.data.list[i].generateCount;
                }
                item.codeCount = codeCount;
                item.validTime = res.data.list[i].startTime + " - " + res.data.list[i].endTime;
              }
            });
          }
        }
      });
    },
    // 优惠券条数
    onSizeCHange(size) {
      this.pageInfo.size = size;
      this.pageInfo.page = 1;
      this.initCouponData();
    },
    // 优惠券页数
    onCurrentChange(page) {
      this.pageInfo.page = page;
      this.initCouponData();
    },
    // 优惠券码条数
    codeSizeChange(size) {
      this.codeInfo.size = size;
      this.codeInfo.page = 1;
      this.initCodeListData(this.curCouponId);
    },
    // 优惠券码页数
    codeCurrentChange(page) {
      this.codeInfo.page = page;
      this.initCodeListData(this.curCouponId);
    },
    // 搜索码库列表
    searchCode() {
      this.codeInfo.page = 1;
      this.initCodeListData(this.curCouponId);
    },
    // 码库 - 作废
    handleInvalidCode(couponNos) {
      this.$prompt("请输入作废说明", "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        inputValidator(value) {
          if (value) {
            return true;
          } else {
            return false;
          }
        },
        inputErrorMessage: "请输入作废说明",
      }).then(({ value }) => {
        console.log(value);
        this.loading = true;

        this.$api.post(this, api.couponNoStatus, { couponNos: couponNos, couponStatus: 5 }).then(
          (res) => {
            if (res.success) {
              this.loading = false;
              this.$message({
                type: "success",
                message: "作废成功",
              });
              this.initCodeListData(this.curCouponId);
            } else {
              this.$message({
                type: "error",
                message: res.msg,
              });
              this.loading = false;
            }
          }
        );
      })
        .catch(() => {
          this.$message({
            type: "info",
            message: "已取消操作",
          });
        });
    },
    // 码库 - 批量作废
    handleInvalidCodeMore() {
      let couponNos = [];
      for (let i = 0; i < this.multipleSelect.length; i++) {
        couponNos.push(this.multipleSelect[i].couponNo);
      }
      if (couponNos.length > 0) {
        this.handleInvalidCode(couponNos);
      } else {
        this.$message({
          type: "error",
          message: "请先选择需要作废的优惠券码",
        });
      }
    },
    selectable(row) {
      if (row.couponStatus !== 0) {
        return true;
      } else {
        return false;
      }
    },
    // 码库 - 单选时调用
    select(selection, row) {
      this.isSelect = true;
      let d = false;
      for (let i = 0; i < this.multipleSelect.length; i++) {
        if (this.multipleSelect[i].id === row.id) {
          this.multipleSelect.splice(i, 1);
          d = true;
          break;
        }
      }
      if (!d) {
        this.multipleSelect.push(row);
        this.multipleSelect = this.setArr(this.multipleSelect);
      }
    },
    // 码库 - 全选时调用
    selectAll(selection) {
      this.isSelect = true;
      if (selection.length === 0) {
        this.codeData.forEach((row) => {
          for (let i = 0; i < this.multipleSelect.length; i++) {
            if (this.multipleSelect[i].id === row.id) {
              this.multipleSelect.splice(i, 1);
              break;
            }
          }
        });
      } else {
        this.multipleSelect = this.setArr(
          this.multipleSelect.concat(selection)
        );
      }
    },
    // 码库 - 当切换页面时的作用
    handleSelectionChange(val) {
      if (
        val.length === 0 &&
        this.multipleSelect.length != 0 &&
        !this.isSelect
      ) {
        this.multipleSelect.forEach((row1) => {
          this.codeData.forEach((row2) => {
            if (row1.id === row2.id) {
              this.$refs.multipleSelect.toggleRowSelection(row2);
            }
          });
        });
        this.isSelect = true;
      }
    },
    setArr(arr) {
      // 去重
      const obj = {};
      const temp = [];
      for (let i = 0; i < arr.length; i++) {
        const type = Object.prototype.toString.call(arr[i].id); // 不加类型 分不清 1 '1'
        if (!obj[arr[i].id + type]) {
          temp.push(arr[i]);
          obj[arr[i].id + type] = true; // 这里给true 利于代码阅读和判断。  如果给 0,'' ,false ,undefined 都会在if那里判断为 false 不利于代码阅读
        }
      }
      return temp;
    },
  }
};
</script>

<style rel="stylesheet/scss" lang="scss" scoped>
.el-select,
.el-autocomplete {
  margin-left: 5px;
}
</style>
