<template>
  <div id="app">
    <div class="container">
      <h2 style="text-align: center; padding-bottom: 20px;">登录</h2>
      <el-form ref="form" label-width="80px">
        <el-form-item label="账号:">
          <el-input v-model="loginForm.userName" style="width: 250px;"></el-input>
        </el-form-item>
        <el-form-item label="密码:">
          <el-input v-model="loginForm.password" type="password" style="width: 250px;"></el-input>
        </el-form-item>
        <el-form-item>
          <el-button type="primary" style="width: 250px;" @click="login">登录</el-button>
        </el-form-item>
      </el-form>
    </div>
  </div>
</template>

<script>
import md5 from 'js-md5'
import api from "../../api/allUrl";
export default {
  name: 'userLogin', // 修改名称 
  data() {
    return {
      loginForm: {
        userName: null,
        password: null
      }
    }
  },
  computed: {
    loginInfo() {
      return {
        userName: this.loginForm.userName,
        password: md5(this.loginForm.password)
      }
    },
    cookieName() {
      return this.loginForm.userName
    }
  },
  beforeDestroy() { // 清除登录页面组件的状态和数据 
  },
  methods: {

    login() {
      if (this.loginForm.userName == "") {
        this.$message({
          message: '请输入账号',
          type: 'error',
          duration: 3 * 1000
        })
      } else if (this.loginForm.password == "") {
        this.$message({
          message: '请输入密码',
          type: 'error',
          duration: 3 * 1000
        })
      } else {
        this.$api.post(this, api.login, this.loginInfo).then((res) => {
          if (res.success) {
            localStorage.setItem('defaultActive', '/index');
            localStorage.setItem('selectIndex', 0);
            this.$router.push('/index');
          }
        });
      }
    },
  }
}
</script>
<style scoped>
#app {
  opacity: 0.9;
  height: 100vh;
  width: 100%;
  margin: 0;
  display: flex;
  justify-content: center;
  align-items: center;
  background: linear-gradient(0deg, #3494e6 0%, #ec6ead 100%);
  /* background-image: url('../images/login.jpg'); */
  background-repeat: no-repeat;
  background-size: cover;
}

.container {
  width: 400px;
  height: 250px;
  padding: 16px;
  background-color: white;
  border-radius: 10px;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
}
</style>