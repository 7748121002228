<template>
  <div>
    <div class="page_header_title_box" v-if="isShow">
      <i class="el-icon-back" @click="handleCancel"></i>
      新增映射分类
    </div>
    <div class="page_header_title_box" v-else>
      <i class="el-icon-back" @click="handleCancel"></i>编辑映射分类
    </div>
    <div style="padding: 70px 20px 20px 20px;">
      <div class="content_box">
        <div class="tip-left">
          <p><code>基本信息</code></p>
        </div>
        <el-form label-width="150px" :rules="rules" ref="formData" :model="formData" v-loading="vloading">
          <el-form-item label="第三方商品品类" prop="commodityCategory">
            <el-input size="mini" style="width:300px;" v-model="formData.commodityCategory" placeholder="请输入第三方商品品类" maxlength="40">
            </el-input>
          </el-form-item>
          <el-form-item label="第三方商品品类编码" prop="commodityCategoryCode">
            <el-input size="mini" style="width:300px;" v-model="formData.commodityCategoryCode" placeholder="请输入第三方商品品类编码" maxlength="40">
            </el-input>
          </el-form-item>
          <el-form-item label="第三方商品分类" prop="commodityClassify">
            <el-input size="mini" style="width:300px;" v-model="formData.commodityClassify" placeholder="请输入第三方商品分类" maxlength="40">
            </el-input>
          </el-form-item>
          <el-form-item label="第三方商品分类编码" prop="code">
            <el-input size="mini" style="width:300px;" v-model="formData.commodityClassifyCode" placeholder="请输入第三方商品分类编码" maxlength="40">
            </el-input>
          </el-form-item>
          <el-form-item label="第三方商品SPU编码" prop="commodityCode">
            <el-input size="mini" style="width:300px;" v-model="formData.commodityCode" placeholder="请输入第三方商品SPU编码" maxlength="40">
            </el-input>
          </el-form-item>
          <el-form-item label="第三方商品SPU名" prop="commodityName">
            <el-input size="mini" style="width:300px;" v-model="formData.commodityName" placeholder="请输入第三方商品SPU名" maxlength="40">
            </el-input>
          </el-form-item>
          <el-form-item label="商品类型id">
            <el-input size="mini" style="width:300px;" v-model="formData.factoryModelId" placeholder="请输入商品类型id" maxlength="40">
            </el-input>
          </el-form-item>
          <el-form-item label="商品类型名称" prop="factoryModelName">
            <el-input size="mini" style="width:300px;" v-model="formData.factoryModelName" placeholder="请输入商品类型名称" maxlength="40">
            </el-input>
          </el-form-item>
        </el-form>
        <div class="clearfix footbtn">
          <el-button type="primary" :loading="dloading" style="margin-left: 46.5%;" @click="handleSubmit()">保存</el-button>
          <el-button @click="handleCancel()">返回</el-button>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import api from "@/api/allUrl";
export default {
  name: "mapping_details",
  data() {
    return {
      isShow: true,
      formData: {
        commodityCategory: "",
        commodityCategoryCode: '',
        commodityClassify: '',
        commodityClassifyCode: '',
        commodityCode: '',
        commodityName: '',
        factoryModelId: '',
        factoryModelName: '',
      },
      rules: {
        commodityCategory: [{
          required: true,
          message: '请输入第三方商品品类',
          trigger: 'blur'
        }], commodityCategoryCode: [{
          required: true,
          message: '请输入第三方商品品类编码',
          trigger: 'blur'
        }], commodityClassify: [{
          required: true,
          message: '请输入第三方商品分类',
          trigger: 'blur'
        }], commodityCode: [{
          required: true,
          message: '请输入第三方商品SPU编码',
          trigger: 'blur'
        }],
        // factoryModelId: [{
        //   required: true,
        //   message: '请输入商品类型id',
        //   trigger: 'blur'
        // }],
        commodityName: [{
          required: true,
          message: '请输入第三方商品SPU名',
          trigger: 'blur'
        }], factoryModelName: [{
          required: true,
          message: '请输入商品类型名称',
          trigger: 'blur'
        }]
      },
    }
  },
  created() {
    this.formData = {
      commodityCategory: "",
      commodityCategoryCode: '',
      commodityClassify: '',
      commodityClassifyCode: '',
      commodityCode: '',
      commodityName: '',
      factoryModelId: '',
      factoryModelName: '',
    };
    if (this.$route.query.checkMsg == 3) {
      this.isShow = false;
      this.getChannelDetails();
    } else {
      this.isShow = true;
    }
  },
  methods: {
    // 必填验证
    handleSubmit() {
      this.dloading = true;
      this.$refs['formData'].validate(valid => {
        if (valid) {
          if (this.isShow) {
            this.$api.post(this, api.addRelevanceList, this.formData).then(res => {
              if (res.success) {
                this.$message.success({
                  message: "新增渠道配置成功",
                  duration: 3 * 1000,
                })
                this.handleCancel();
              }
              this.dloading = false;
            })
          } else {
            this.updateDevicetypedetails();
          }

        } else {
          this.$message({
            message: '请先将信息补充完整',
            type: 'error',
            duration: 3 * 1000,
            onClose: () => {
            }
          })
          this.dloading = false;
        }
      })
    },
    updateDevicetypedetails() {
      this.formData.id = this.$route.query.id;
      this.$api.post(this, api.updateRelevanceList, this.formData).then(res => {
        if (res.success) {
          this.$message.success({
            message: "修改成功",
            duration: 3 * 1000,
          })
          this.handleCancel();
        }
        this.dloading = false;
      })
    },
    getChannelDetails() {
      this.$api.get(this, api.getRelevanceDetail, {
        id: this.$route.query.id
      }).then(res => {
        this.formData = res.data;
      })
    },
    handleCancel() { // 返回操作
      this.$router.go(-1)
    },
  }
};
</script>
<style lang="scss" scoped>
</style>
