<template>
  <div>
    <div class="page_header_title_box">切膜订单
      <el-button class="mini_add_btn" @click="exportShow=true">导出订单</el-button>
    </div>
    <div style="padding: 70px 20px 20px 20px;">
      <div class="content_box">
        <div class="heading_box">
          <div class="search_for_box">
            <el-select class="mini_select_bit" size="mini" v-model="pageInfo.type" placeholder="订单类型" style="width: 100px;margin-right: 5px;" clearable>
              <el-option label="定制" :value="0"></el-option>
              <el-option label="预制" :value="1"></el-option>
            </el-select>
            <el-select class="mini_select_bit" size="mini" v-model="pageInfo.payType" placeholder="付费类型" style="width: 100px;margin-right: 5px;" clearable>
              <el-option label="免费" :value="0"></el-option>
              <el-option label="付费" :value="1"></el-option>
            </el-select>
            <el-select class="mini_select_bit" size="mini" v-model="pageInfo.status" placeholder="订单状态" style="width: 100px;margin-right: 5px;" clearable>
              <el-option label="成功" :value="0"></el-option>
              <el-option label="失败" :value="1"></el-option>
            </el-select>
            <el-select class="mini_select_bit" size="mini" v-model="pageInfo.deviceCategoryId" placeholder="设备类型" style="width: 180px;margin-right: 5px;" clearable>
              <el-option v-for="item,index in devicetypeList" :key="index" :label="item.name" :value="item.id"></el-option>
            </el-select>
            <el-autocomplete size="mini" style="width: 200px;" v-model="distributorName" :fetch-suggestions="querySearchAsync" placeholder="请输入分销商名称" @select="handleSelect"></el-autocomplete>
            <el-autocomplete size="mini" style="width: 200px;margin-left: 5px;" v-model="shopName" :fetch-suggestions="querySearchshopAsync" placeholder="请输入店铺名称" @select="handleshopSelect"></el-autocomplete>
          </div>
          <div class="search_for_box">
            <el-select class="mini_select_bit" size="mini" v-model="pageInfo.contentType" placeholder="请选择" style="width: 180px;margin-right: 5px;" clearable>
              <el-option label="切膜订单号" :value="1"></el-option>
              <el-option label="设备ID" :value="2"></el-option>
              <el-option label="机器编码" :value="3"></el-option>
              <el-option label="客户姓名" :value="4"></el-option>
              <el-option label="客户手机号" :value="5"></el-option>
              <el-option label="切膜类型" :value="6"></el-option>
              <el-option label="商品品类" :value="7"></el-option>
              <el-option label="商品分类" :value="8"></el-option>
              <el-option label="商品名称" :value="9"></el-option>
              <el-option label="营业员姓名" :value="10"></el-option>
              <el-option label="营业员电话" :value="11"></el-option>
            </el-select>
            <el-input placeholder="请输入内容" v-model="pageInfo.content" @keyup.enter.native="onSearch()">
              <el-button slot="append" icon="el-icon-search" @click.prevent="onSearch()"></el-button>
            </el-input>
          </div>
        </div>
        <el-table :data="deviceOrderList" v-loading="loading">
          <el-table-column label="切膜订单号" align="center" prop="recordNo" width="140"></el-table-column>
          <el-table-column label="分销商名称" align="center" prop="distributorName" width="140"></el-table-column>
          <el-table-column label="店铺名称" align="center" prop="shopName" width="140"></el-table-column>
          <el-table-column label="订单类型" align="center" prop="type" width="140">
            <template slot-scope="scope">
              {{ scope.row.payType == 0 ? '预制' :  scope.row.payType == 1?'定制':'-' }}
            </template>
          </el-table-column>
          <el-table-column label="付费类型" align="center" prop="payType" width="140">
            <template slot-scope="scope">
              {{ scope.row.payType == 0 ? '免费' :  scope.row.payType == 1?'付费':'-' }}
            </template>
          </el-table-column>
          <el-table-column label="创建时间" align="center" prop="createTime" width="140"></el-table-column>
          <el-table-column label="订单状态" align="center" prop="status" width="140">
            <template slot-scope="scope">
              {{ scope.row.payType == 0 ? '成功' :scope.row.payType == 1?'失败':'-' }}
            </template>
          </el-table-column>
          <el-table-column label="设备类型" align="center" prop="deviceCategoryName" width="140"></el-table-column>
          <el-table-column label="设备ID" align="center" prop="deviceNo" width="140"></el-table-column>
          <el-table-column label="设备编码" align="center" prop="deviceCode" width="140"></el-table-column>
          <el-table-column label="客户姓名" align="center" prop="customerName" width="140"></el-table-column>
          <el-table-column label="客户手机号" align="center" prop="customerPhone" width="140"></el-table-column>
          <el-table-column label="扫码手机型号" align="center" prop="customerPhoneModel" width="140"></el-table-column>
          <el-table-column label="身份证号" align="center" prop="customerCardId" width="140"></el-table-column>
          <el-table-column label="切膜类型" align="center" prop="modelName" width="140"></el-table-column>
          <el-table-column label="切膜品牌" align="center" prop="customerPhoneBrand" width="140"></el-table-column>
          <el-table-column label="切膜型号" align="center" prop="customerPhoneModel" width="140"></el-table-column>
          <el-table-column label="商品品类" align="center" prop="commodityCategory" width="140"></el-table-column>
          <el-table-column label="品类编码" align="center" prop="commodityCategoryCode" width="140"></el-table-column>
          <el-table-column label="商品分类" align="center" prop="commodityClassify" width="140"></el-table-column>
          <el-table-column label="分类编码" align="center" prop="commodityClassifyCode" width="140"></el-table-column>
          <el-table-column label="商品名称" align="center" prop="commodityNameSpu"></el-table-column>
          <el-table-column label="商品编码" align="center" prop="commodityCodeSpu" width="140"></el-table-column>
          <el-table-column label="营业员姓名" align="center" prop="staffName" width="140"></el-table-column>
          <el-table-column label="营业员电话" align="center" prop="staffPhone" width="140"></el-table-column>
          <el-table-column label="店员佣金" align="center" prop="staffCommission" width="140"></el-table-column>
          <el-table-column label="店长佣金" align="center" prop="storeManagerCommission" width="140"></el-table-column>
        </el-table>
        <el-pagination :total="total" :page="pageInfo.page" @size-change="onSizeCHange" @current-change="onCurrentChange">
        </el-pagination>
      </div>
    </div>
    <el-dialog :visible="exportShow" width="700px" title="导出订单" :center="true" :before-close="closeLog">
      <el-form ref="form" label-width="120px">
        <el-form-item label="导出时间范围">
          <el-date-picker size="mini" v-model="exportData.time" style="width: 330px" type="datetimerange" value-format="timestamp" range-separator="至" start-placeholder="开始时间" end-placeholder="结束时间"></el-date-picker>
        </el-form-item>
        <el-form-item label="分销商">
          <el-autocomplete size="mini" style="width: 200px;" v-model="distributorName1" :fetch-suggestions="querySearchAsync" placeholder="请输入分销商名称" @select="handleSelect1"></el-autocomplete>
        </el-form-item>
        <el-form-item label="店铺">
          <el-autocomplete size="mini" style="width: 200px;margin-left: 5px;" v-model="shopName1" :fetch-suggestions="querySearchshopAsync1" placeholder="请输入店铺名称" @select="handleshopSelect1"></el-autocomplete>
        </el-form-item>
        <el-form-item label="订单类型">
          <el-checkbox-group v-model="exportData.type">
            <el-checkbox label="0">定制</el-checkbox>
            <el-checkbox label="1">预制</el-checkbox>
          </el-checkbox-group>
        </el-form-item>
        <el-form-item label="付费类型">
          <el-checkbox-group v-model="exportData.payType">
            <el-checkbox label="0">免费</el-checkbox>
            <el-checkbox label="1">付费</el-checkbox>
          </el-checkbox-group>
        </el-form-item>
        <el-form-item label="订单状态">
          <el-checkbox-group v-model="exportData.status">
            <el-checkbox label="0">成功</el-checkbox>
            <el-checkbox label="1">失败</el-checkbox>
          </el-checkbox-group>
        </el-form-item>
        <el-form-item label="导出字段">
          <el-checkbox-group v-model="exportData.exportField">
            <el-checkbox label="recordNo" disabled>切膜订单号</el-checkbox>
            <el-checkbox label="deviceCategoryName" disabled>设备类型</el-checkbox>
            <el-checkbox label="deviceNo" disabled>设备ID</el-checkbox>
            <el-checkbox label="deviceCode" disabled>设备编码</el-checkbox>
            <el-checkbox label="modelName" disabled>切膜类型</el-checkbox>
            <el-checkbox label="customerPhoneBrand" disabled>切膜品牌</el-checkbox>
            <el-checkbox label="customerPhoneModel" disabled>切膜型号</el-checkbox>
            <!-- <el-checkbox label="modelId" disabled>切膜型号</el-checkbox> -->
            <el-checkbox label="distributorName">分销商名称</el-checkbox>
            <el-checkbox label="shopName">店铺名称</el-checkbox>
            <!-- <el-checkbox label="machineType">切膜品牌</el-checkbox> -->
            <el-checkbox label="customerName">客户姓名</el-checkbox>
            <el-checkbox label="customerPhone">客户手机号</el-checkbox>
            <!-- <el-checkbox label="customerCardId">身份证号</el-checkbox> -->
            <el-checkbox label="staffName">营业员姓名</el-checkbox>
            <el-checkbox label="staffPhone">营业员电话</el-checkbox>
            <el-checkbox label="staffCommission">店员佣金</el-checkbox>
            <el-checkbox label="storeManagerCommission">店长佣金</el-checkbox>
            <el-checkbox label="commodityCategory">商品品类</el-checkbox>
            <el-checkbox label="commodityCategoryCode">品类编码</el-checkbox>
            <el-checkbox label="commodityClassify">商品分类</el-checkbox>
            <el-checkbox label="commodityClassifyCode">分类编码</el-checkbox>
            <el-checkbox label="commodityNameSpu">商品名称</el-checkbox>
            <el-checkbox label="commodityCodeSpu">商品编码</el-checkbox>
          </el-checkbox-group>
        </el-form-item>
        <div class="button_box" style="margin-top: 40px">
          <div class="exchange_bit" @click="confirmExport()">确认导出</div>
          <div class="return_bit" @click="closeLog()">返回</div>
        </div>
      </el-form>
    </el-dialog>
  </div>
</template>
<script>
import axios from 'axios'
import { parseTime } from "@/utils/common.js";
import api from "@/api/allUrl";
export default {
  name: "deviceOrderList",
  data() {
    return {
      exportShow: false,
      pageInfo: {
        content: '',
        contentType: '',
        deviceCategoryId: '',
        page: 1,
        size: 10,
        payType: "",
        status: '',
        type: '',
        distributorId: undefined,
        shopId: undefined
      },
      distributorName: '',
      shopName: '',
      distributorName1: '',
      shopName1: '',
      devicetypeList: [],
      deviceOrderList: [],
      total: 1,
      loading: false,
      exportData: {
        exportField: ['recordNo', 'deviceCategoryName', 'deviceId', 'deviceCode', 'modelName', 'customerPhoneBrand', 'customerPhoneModel',],
        status: [],
        type: [],
        payType: [],
        endTime: '',//结束时间
        startTime: '',//开始时间
        distributorId: undefined,
        shopId: undefined
      }
    }
  },
  created() {
    this.getDevicetypeList();
    this.loading = true;
    this.getOrderList();
  },
  methods: {
    handleSelect(item) {
      if (this.pageInfo.distributorId != item.id) {
        this.exportData.shopId = '';
        this.shopName = '';
      }
      this.pageInfo.distributorId = item.id;
    },
    handleshopSelect(item) {
      this.pageInfo.shopId = item.id
    },
    handleSelect1(item) {
      if (this.exportData.distributorId != item.id) {
        this.exportData.shopId = '';
        this.shopName1 = '';
      }
      this.exportData.distributorId = item.id;
    },
    handleshopSelect1(item) {
      this.exportData.shopId = item.id
    },
    querySearchshopAsync1(queryString, cb) {
      let pageInfo = {
        content: queryString,
        openFlag: 1,
        page: 1,
        size: 10,
        distributorId: this.exportData.distributorId
      }
      this.$api.get(this, api.shopList, pageInfo).then(res => {
        if (res.success) {
          var restaurants = []
          res.data.list.forEach(item => {
            restaurants.push({
              "value": item.shopName,
              "id": item.id,
            })
          })
        }
        cb(restaurants)
      })
    },
    querySearchshopAsync(queryString, cb) {
      let pageInfo = {
        content: queryString,
        openFlag: 1,
        page: 1,
        size: 10,
        distributorId: this.pageInfo.distributorId
      }
      this.$api.get(this, api.shopList, pageInfo).then(res => {
        if (res.success) {
          var restaurants = []
          res.data.list.forEach(item => {
            restaurants.push({
              "value": item.shopName,
              "id": item.id,
            })
          })
        }
        cb(restaurants)
      })
    },
    querySearchAsync(queryString, cb) {
      let pageInfo = {
        page: 1,
        size: 10,
        freezeStatus: 1,
        contentType: 1,
        content: queryString
      }
      this.$api.get(this, api.getDistributorNPoList, pageInfo).then(res => {
        if (res.success) {
          var restaurants = []
          res.data.list.forEach(item => {
            restaurants.push({
              "value": item.name,
              "id": item.id,
            })
          })
        }
        cb(restaurants)
      })
    },
    confirmExport() {
      var exportField = this.exportData.exportField.filter(field => field !== 'recordNo' && field !== 'deviceCategoryName' && field !== 'deviceId' && field !== 'deviceCode' && field !== 'modelName' && field !== 'customerPhoneModel');
      // if (exportField.length == 15) {
      //   exportField = []
      // }
      var tenantUrl = '';
      tenantUrl = process.env.VUE_APP_API_URL + api.exportOrderrecords;
      axios({
        method: 'post',
        url: tenantUrl,
        data: {
          exportField: exportField,
          status: this.exportData.status.join(',') == '0,1' ? '' : this.exportData.status.join(','),
          type: this.exportData.type.join(',') == '0,1' ? '' : this.exportData.type.join(','),
          payType: this.exportData.payType.join(',') == '0,1' ? '' : this.exportData.payType.join(','),
          endTime: this.exportData.endTime,
          startTime: this.exportData.startTime,
          distributorId: this.exportData.distributorId,
          shopId: this.exportData.shopId
        },
        responseType: 'arraybuffer',
        headers: {
          'Content-Type': 'application/json',
          'token': localStorage.getItem('token'),
          'tenantNo': 100,
          'value': 100
        }
      }).then(res => {
        const content = res.data
        let blob = new Blob([content], {
          type: "application/ms-excel"
        })
        let url = window.URL.createObjectURL(blob)
        if ('download' in document.createElement('a')) {
          let link = document.createElement('a')
          link.style.display = 'none'
          link.href = url
          link.setAttribute('download', '设备订单列表.xls')
          document.body.appendChild(link)
          link.click()
        } else {
          navigator.msSaveBlob(blob, '设备订单列表.xls')
        }
      })


    },
    closeLog() {
      this.exportShow = false;
    },
    getOrderList() {
      this.$api.get(this, api.getDeviceOrderList, this.pageInfo).then(res => {
        this.deviceOrderList = res.data.list;
        this.total = res.data.total;
        this.loading = false;
      })
    },
    getDevicetypeList() {
      this.$api.get(this, api.getDevicetypeList, {
        page: 1,
        size: 10,
        content: '',
        contentType: undefined
      }).then(res => {
        this.devicetypeList = res.data.list;
      })
    },
    onSizeCHange(val) {
      // 分页方法
      this.loading = true;
      this.pageInfo.size = val;
      this.pageInfo.page = 1;
      this.getOrderList();
    },
    onCurrentChange(val) {
      // 分页方法
      this.loading = true;
      this.pageInfo.page = val;
      this.getOrderList();
    },
    onSearch() {
      this.loading = true;
      this.pageInfo.page = 1;
      this.getOrderList();
    },
  }, watch: {
    "exportData.time": {
      handler(val) {
        if (val) {
          this.exportData.startTime = parseTime(val[0])
          this.exportData.endTime = parseTime(val[1])
        } else {
          this.exportData.startTime = undefined
          this.exportData.endTime = undefined
        }
      },
      deep: true
    }
  }
};
</script>

<style rel="stylesheet/scss" lang="scss" scoped>
</style>
