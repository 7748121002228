<template>
  <div>
    <div class="page_header_title_box">
      通用次数列表
    </div>
    <div style="padding: 70px 20px 20px 20px;">
      <div class="content_box">
        <div class="heading_box">
          <div class="search_for_box">
            <el-button class="mini-add-btn btn-home" @click="importdevicelist(2)">导入调整次数</el-button>
          </div>
          <div class="search_for_box">
            <el-input placeholder="请输入套餐名称" v-model="pageInfo.content" @keyup.enter.native="onSearch()">
              <el-button slot="append" icon="el-icon-search" @click.prevent="onSearch()"></el-button>
            </el-input>
          </div>
        </div>
        <el-table :data="tableData" header-row-class-name="header-row" class="tableCenter" v-loading="loading">
          <el-table-column label="分销商Id" width="120" align="center" prop="distributorId" fixed></el-table-column>
          <el-table-column label="分销商用户名" align="center" prop="distributorName" fixed></el-table-column>
          <el-table-column label="总购买切膜次数" align="center" prop="cutCount"></el-table-column>
          <el-table-column label="总购买VIP天数" align="center" prop="buyDay"></el-table-column>
          <el-table-column label="总调整切膜次数" align="center" prop="changeCount"></el-table-column>
          <el-table-column label="总调整VIP天数" align="center" prop="changeDay"></el-table-column>
          <el-table-column label="购买膜品赠送切膜次数" align="center" prop="reCount"></el-table-column>
          <el-table-column label="已兑换膜品切膜次数" align="center" prop="filmCount"></el-table-column>
          <el-table-column label="未兑换膜品切膜次数" align="center" prop="unFilmCount"></el-table-column>
          <el-table-column label="操作" align="center" fixed="right">
            <template slot-scope="scope">
              <el-button class="mini-search-btn" @click="viewLog(scope.row.deviceNo)">查看日志</el-button>
            </template>
          </el-table-column>
        </el-table>
        <el-pagination :total="total" :page="pageInfo.page" @sizeChange="onSizeCHange" @currentChange="onCurrentChange">
        </el-pagination>
      </div>
    </div>
    <!-- 查看日志 -->
    <el-dialog :visible="lookLogShow" width="70%" title="查看日志" :before-close="closeLog">
      <div style="margin-bottom: 20px; display: flex;
          justify-content: space-between;">
        <div>
          <el-date-picker size="mini" v-model="logPageInfo.time" style="width: 330px" type="datetimerange" value-format="timestamp" range-separator="至" start-placeholder="开始日期" end-placeholder="结束日期"></el-date-picker>

          <!-- 1 购买膜品 2 次数充值 3 天数充值 4 次数分配 5 膜码兑换 6 数据调整 -->

          <el-select size="mini" v-model="logPageInfo.businessType" placeholder="业务类型" style="width: 100px" clearable>
            <el-option label="总充值次数" :value="2"></el-option>
            <el-option label="总购买VIP天数" :value="4"></el-option>
            <el-option label="购买膜品总切膜次数" :value="1"></el-option>
            <el-option label="已兑换膜品切膜次数" :value="5"></el-option>

            <el-option label="调整天数" :value="3"></el-option>
            <el-option label="切膜消耗" :value="6"></el-option>
            <el-option label="赠送次数" :value="7"></el-option>
          </el-select>

        </div>
        <div style="display: flex;">
          <el-select size="mini" v-model="logPageInfo.contentType" placeholder="搜索内容类型" style="width: 180px" clearable>
            <el-option label="业务单号" :value="1"></el-option>
            <el-option label="兑换膜码" :value="2"></el-option>
            <el-option label="手机号" :value="3"></el-option>
            <el-option label="设备号" :value="4"></el-option>
          </el-select>
          <el-input placeholder="请输入内容" v-model="logPageInfo.content" @keyup.enter.native="searchLos()">
            <el-button slot="append" icon="el-icon-search" @click.prevent="searchLos()"></el-button>
          </el-input>
        </div>
      </div>
      <el-table :data="lookLogs" max-height="600" style="width: 100%" header-row-class-name="header-row" class="tableCenter" v-loading="lookLogLoading">
        <el-table-column align="center" label="业务单号" width="140" prop="businessId"></el-table-column>
        <el-table-column label="变化类型" align="center" prop="businessType" width="100">
          <template slot-scope="scope">
            {{ frequencyName(scope.row.businessType) }}
          </template>
        </el-table-column>
        <el-table-column align="center" label="变化数额" width="90" prop="beforeCountOrDay"></el-table-column>
        <el-table-column align="center" label="操作时间" prop="createTime"></el-table-column>
        <el-table-column align="center" label="备注" prop="remark"></el-table-column>
      </el-table>
      <el-pagination :total="logTotal" :page="logPageInfo.page" @size-change="onlosSizeCHange" @current-change="onlosCurrentChange">
      </el-pagination>
    </el-dialog>
  </div>
</template>
<script>
import api from "@/api/allUrl";
import { parseTime } from "@/utils/common.js";
export default {
  name: "UniversalFrequencyComponent",
  data() {
    return {
      logPageInfo: {
        page: 1,
        size: 10,
      },
      lookLogShow: false,
      lookLogs: [],
      purchaseType: 1,
      tableData: [],
      logTotal: 1,
      total: 1,
      pageInfo: {
        page: 1,
        size: 10,
        content: ''
      },
      loading: false,
      lookLogLoading: false
    }
  },
  created() {
    this.loading = true;
    this.tableData = [];
    this.getTimesList();
  },
  methods: {
    onSearch() {
      this.getTimesList();
    },
    importdevicelist(index) {
      var query = { checkMsg: index };
      this.$router.push({ name: 'import_devicelist', query: query })
    },
    //搜索日志
    searchLos() {

      this.logPageInfo.page = 1;
      this.lookLogLoading = true;
      this.getDevicevlog();
    },
    onlosSizeCHange(val) {
      // 分页方法
      this.logPageInfo.size = val;
      this.lookLogLoading = true;
      this.getDevicevlog();
    },
    onlosCurrentChange(val) {
      // 分页方法
      this.logPageInfo.page = val;
      this.lookLogLoading = true;
      this.getDevicevlog();
    },
    //查看日志
    viewLog(deviceNo) {
      this.lookLogShow = true;
      this.lookLogLoading = true;
      this.logPageInfo = {
        page: 1,
        size: 10,
        distributorId: undefined,
        content: '',
        contentType: undefined,//1 业务单号 2 兑换膜码 3 手机号 4 设备号 
        businessType: undefined,//1 调整次数 2 购买次数 3 调整天数 3 购买天数 4 膜码兑换 5 切膜消耗
        endTime: '',//结束时间 
        startTime: '',//开始时间
      };
      this.logPageInfo.deviceNo = deviceNo;
      this.getDevicevlog();
    },
    getDevicevlog() {
      this.$api.get(this, api.getTimesRecord, this.logPageInfo).then(res => {
        if (res.success) {
          this.lookLogs = res.data.list;
          this.logTotal = res.data.total;
        }
        this.lookLogLoading = false;
      })
    },
    getTimesList() {
      this.$api.get(this, api.getTimesList, this.pageInfo).then(res => {
        if (res.success) {
          this.tableData = res.data.list;
          this.total = res.data.total;
        }
        this.loading = false;
      })
    },
    closeLog() {
      this.lookLogShow = false
    },
    onSizeCHange(val) {
      // 分页方法
      this.pageInfo.size = val;
      this.getTimesList();
    },
    onCurrentChange(val) {
      // 分页方法
      this.pageInfo.page = val;
      this.getTimesList();
    },
    frequencyName(v) {
      switch (v) {
        case 0:
          return '购买膜品';
        case 1:
          return '调整次数';
        case 2:
          return '购买次数';
        case 3:
          return '调整天数';
        case 4:
          return '购买天数';
        case 5:
          return '膜码兑换';
        case 6:
          return '切膜消耗';
        case 7:
          return '容错次数';
        default:
          return '-'
      }
    }
  },
  watch: {
    "logPageInfo.time": {
      handler(val, oldVal) {
        if (val) {
          this.logPageInfo.startTime = parseTime(val[0])
          this.logPageInfo.endTime = parseTime(val[1])
        } else {
          this.logPageInfo.startTime = undefined
          this.logPageInfo.endTime = undefined
        }
        if (val !== oldVal) {
          this.logPageInfo.page = 1;
        }
      },
      deep: true
    }
  }
};
</script>

<style rel="stylesheet/scss" lang="scss" scoped>
.purchase_types_box_item {
  width: 120px;
  height: 40px;
  line-height: 40px;
  border-radius: 10px;
  border: 2px solid #ff7500;
  color: #ff7500;
  font-size: 14px;
  text-align: center;
  box-shadow: 0px 2px 20px 0px rgba(0, 0, 0, 0.08);
  font-family: Roboto;
  margin-right: 10px;
  cursor: pointer;
}

.ewm_title {
  color: #101010;
  font-size: 22px;
  text-align: center;
  font-family: SourceHanSansSC-regular;
  padding-top: 10px;
}

.goods_ewm {
  position: fixed;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  display: block;
  width: 800px;
  height: 500px;
  z-index: 99;
  margin: auto;
  background: #fff;
  text-align: center;
  border-radius: 10px;

  .shop_form_box {
    width: 700px;
    height: auto;
    margin: 0 auto;
    margin-top: 20px;
    overflow: hidden;
    overflow-y: auto;
    text-align: left;
    margin-bottom: 10px;

    .device_data_box {
      height: 100px;
      line-height: 20px;
      border-radius: 10px 10px 10px 10px;
      background-color: rgba(245, 245, 245, 1);
      text-align: center;
      font-family: Roboto;
      margin-top: 20px;
      display: flex;
      justify-content: space-between;
      align-items: center;
      padding: 0 40px;
      color: rgba(59, 59, 59, 1);
      font-size: 14px;
      box-sizing: border-box;

      span {
        color: rgba(252, 126, 27, 1);
        font-size: 18px;
        font-weight: 600;
        font-family: SourceHanSansSC-regular;
        margin-left: 10px;
      }
    }

    .exchange_purchase_box {
      width: 698px;
      height: auto;
      border-radius: 10px;
      // border: 1px solid rgba(236, 238, 245, 1);
      // margin-top: 20px;
      padding: 30px;
      box-sizing: border-box;

      .types_of_illustrate_box {
        width: 550px;
        height: auto;
        border-radius: 15px;
        text-align: left;
        font-family: Roboto;
        padding: 10px;
        box-sizing: border-box;
        margin-top: 10px;
        color: rgba(148, 146, 146, 1);
        font-size: 14px;
        font-family: SourceHanSansSC-regular;

        .types_of_illustrate_title {
          color: #ff7500;
          font-size: 14px;
          text-align: left;
          font-family: SourceHanSansSC-regular;
          line-height: 16px;
        }
      }

      .return_goods_types_box {
        display: flex;

        .return_goods_types_meu {
          width: 120px;
          height: 40px;
          line-height: 40px;
          border-radius: 10px;
          background-color: rgba(255, 255, 255, 1);
          color: rgba(16, 16, 16, 1);
          font-size: 14px;
          text-align: center;
          box-shadow: 0px 2px 20px 0px rgba(0, 0, 0, 0.08);
          font-family: Roboto;
          border: 2px solid rgba(38, 37, 37, 0.09);
          margin-right: 10px;
          cursor: pointer;
        }
      }
    }
  }
}

.cover {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: #000;
  z-index: 99;
  opacity: 0.5;
}
</style>
