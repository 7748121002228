import { render, staticRenderFns } from "./materialList.vue?vue&type=template&id=118a54aa&scoped=true"
import script from "./materialList.vue?vue&type=script&lang=js"
export * from "./materialList.vue?vue&type=script&lang=js"
import style0 from "./materialList.vue?vue&type=style&index=0&id=118a54aa&prod&rel=stylesheet%2Fscss&lang=scss&scoped=true"


/* normalize component */
import normalizer from "!../../../../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "118a54aa",
  null
  
)

export default component.exports