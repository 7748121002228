<template>
  <div>
    <div class="page_header_title_box">
      切膜套餐列表
    </div>
    <div style="padding: 70px 20px 20px 20px;">
      <div class="content_box">
        <div class="heading_box">
          <div class="search_for_box">
            <el-button class="mini_add_btn" @click="addpuls(1)">+ 添加套餐</el-button>
          </div>
          <div class="search_for_box">
            <el-select size="mini" v-model="pageInfo.type" placeholder="套餐形式" style="width: 180px" clearable>
              <el-option label="按次数" :value="1"></el-option>
              <el-option label="按天数" :value="2"></el-option>
            </el-select>
            <el-select size="mini" v-model="pageInfo.openFlag" placeholder="套餐状态" style="width: 180px;margin-right: 5px;" clearable>
              <el-option label="启用" :value="1"></el-option>
              <el-option label="禁用" :value="2"></el-option>
            </el-select>
            <el-input placeholder="请输入内容" v-model="pageInfo.content" @keyup.enter.native="onSearch()">
              <el-button slot="append" icon="el-icon-search" @click.prevent="onSearch()"></el-button>
            </el-input>
          </div>
        </div>
        <el-table :data="tableData" header-row-class-name="header-row" class="tableCenter" v-loading="loading">
          <el-table-column label="套餐名称" align="center" prop="name" width="220"></el-table-column>
          <el-table-column label="套餐说明" align="center" prop="remark"></el-table-column>
          <el-table-column label="套餐形式" align="center" width="100">
            <template slot-scope="scope">
              {{ scope.row.type == 1 ? '按次数' : '按天数/台' }}
            </template>
          </el-table-column>
          <el-table-column label="次/天数" align="center" prop="countOrDay" width="140"></el-table-column>
          <!-- <el-table-column label="单价（元）" align="center" prop="price" width="140"></el-table-column> -->
          <el-table-column label="排序" align="center" prop="sort" width="120"></el-table-column>
          <el-table-column label="状态" align="center" width="120">
            <template slot-scope="scope">
              {{ scope.row.openFlag == 1 ? '启用' : '禁用' }}
            </template>
          </el-table-column>
          <el-table-column label="创建时间" align="center" prop="createTime" width="180"></el-table-column>
          <el-table-column label="操作" width="280" align="center" fixed="right">
            <template slot-scope="scope">
              <el-button type="warning" @click="openDevice(scope.row)">
                {{scope.row.openFlag== 1 ? '禁用' : '启用' }}
              </el-button>
              <el-button @click="addpuls(3, scope.row.id)">
                编辑
              </el-button>
              <el-button type="danger" v-if="scope.row.openFlag!= 1" @click="handleDelete(scope.row)">
                删除
              </el-button>
            </template>
          </el-table-column>
        </el-table>
        <el-pagination :total="total" :page="pageInfo.page" @size-change="onSizeCHange" @current-change="onCurrentChange">
        </el-pagination>
      </div>
    </div>
  </div>
</template>
<script>
import api from "@/api/allUrl";
export default {
  name: "cutPackage",
  data() {
    return {
      total: 1,
      pageInfo: {
        page: 1,
        size: 10,
        type: undefined,
        openFlag: undefined,
        content: ''
      },
      loading: false,
      tableData: []
    }
  },
  created() {
    this.tableData = [];
    this.loading = true;
    this.getMembranePackageList();
  },
  methods: {
    //删除膜码
    handleDelete(row) {
      this.$confirm("此操作将永久删除该套餐，是否继续？", "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning",
        center: true
      }).then(res => {
        console.log(res);
        this.$api.delete(this, api.deleteMembranePackageopen, { id: row.id }).then(res => {
          if (res.success) {
            this.$message.success({
              message: "删除成功",
              duration: 3 * 1000,
            });
            this.pageInfo.page = 1;
            this.pageInfo.content = '';
            this.loading = true;
            this.getMembranePackageList();
          }
        })
      }).catch(res => {
        console.log(res);
        this.$message({
          type: "info",
          message: "已取消删除"
        });
      });
    },
    //启用禁用膜码
    openDevice(row) {
      var mess = '启用该套餐，是否继续？';
      var openFlag = 1;
      if (row.openFlag == 1) {
        mess = '此操作将禁用该套餐，是否继续？';
        openFlag = 0;
      }
      this.$confirm(mess, "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning",
        center: true
      }).then(res => {
        console.log(res);
        this.$api.put(this, api.membranePackageopen, {
          id: row.id,
          openFlag: openFlag
        }).then(res => {
          if (res.success) {
            this.$message.success({
              message: "操作成功",
              duration: 3 * 1000,
            });
            this.loading = true;
            this.getMembranePackageList();
          }
        })
      }).catch(res => {
        console.log(res);
        this.$message({
          type: "info",
          message: "已取消"
        });
      });
    },
    onSearch() {
      this.loading = true;
      this.pageInfo.page = 1;
      this.getMembranePackageList();
    },
    //获取套餐列表
    getMembranePackageList() {
      this.$api.get(this, api.getMembranePackageList, this.pageInfo).then(res => {
        if (res.success) {
          this.tableData = res.data.list;
          this.total = res.data.total;
        }
        this.loading = false;
      })
    },
    addpuls(checkMsg, id) {
      var query = {};
      if (id) {
        query = {
          checkMsg: checkMsg,
          id: id,
        };
      } else {
        query = {
          checkMsg: checkMsg,
        };
      }
      this.$router.push({
        name: "add_package",
        query: query,
      });
    },
    onSizeCHange(val) {
      // 分页方法
      this.loading = true;
      this.pageInfo.size = val;
      this.pageInfo.page = 1;
      this.getMembranePackageList();
    },
    onCurrentChange(val) {
      // 分页方法
      this.loading = true;
      this.pageInfo.page = val;
      this.getMembranePackageList();
    },
  }
};
</script>

<style rel="stylesheet/scss" lang="scss" scoped>
.el-select,
.el-autocomplete {
  margin-left: 5px;
}
</style>
