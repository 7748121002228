<template>
  <div>
    <div class="material_title_box">
      {{addtype==0?'新增总分类':addtype==1?'新增子分类':'编辑分类信息'}}
    </div>
    <div class="material_linq"></div>
    <div class="material_form_box">
      <el-form :model="formData" :rules="rules" label-width="20%" label-position="right" ref="ruleForm">
        <el-form-item label="图片分类名称" prop="name">
          <el-input v-model="formData.name" placeholder="请输入材料名称" />
        </el-form-item>
        <el-form-item label="可用材料分类" prop="materialCategoryIds">
          <el-select v-model="formData.materialCategoryIds" multiple placeholder="请选择">
            <el-option v-for="item in categorylist" :key="item.id" :label="item.name" :value="item.id">
            </el-option>
          </el-select>
        </el-form-item>
        <el-form-item label="状态" prop="status" style="margin-bottom: 0px;">
          <el-radio-group v-model="formData.status" size="mini">
            <el-radio :label="1">启用</el-radio>
            <el-radio :label="0">禁用</el-radio>
          </el-radio-group>
        </el-form-item>
        <!-- <el-form-item label="可视权限" prop="status" style="margin-bottom: 0px;">
          <el-radio-group v-model="formData.status" size="mini">
            <el-radio :label="1">全部分销商</el-radio>
            <el-radio :label="0">指定分销商可见</el-radio>
            <el-radio :label="0">指定分销商不可见</el-radio>
          </el-radio-group>
          <el-button class="mini-search-btn" @click="distributorShow = true">选择分销商</el-button>
          <div style="width: 550px;margin-top: 10px">
            <el-table :data="distributorData" border header-row-class-name="header-row" max-height="600" class="tableCenter">
              <el-table-column align="center" label="分销商ID" width="120" prop="id"></el-table-column>
              <el-table-column align="center" label="分销商名称" width="320" prop="name"></el-table-column>
              <el-table-column align="center" label="操作">
                <template slot-scope="scope">
                  <el-button style="margin-top:0px;margin-bottom:0px;" class="mini-delete-btn" @click="handleDeleteDistributor(scope.$index)">
                    删除
                  </el-button>
                </template>
              </el-table-column>
            </el-table>
          </div>
        </el-form-item> -->
      </el-form>
    </div>
    <div class="button_box">
      <div class="exchange_bit" @click="submitData()">保存</div>
      <div class="return_bit" v-if="addtype!=0" @click="deleteMaterial">删除</div>
    </div>
    <el-dialog :modal-append-to-body="false" :visible="distributorShow" :before-close="disCancel" width="80%">
      <selectDistributor :distributorData="distributorData" :multipleChoice="true" ref="selectDistributor" @cancel="cancel" @submit="disSubmit">
      </selectDistributor>
    </el-dialog>
  </div>
</template>
<script>
import api from "@/api/allUrl";
import selectDistributor from "@/components/distributorList"
export default {
  props: ['managerId', "addtype", "categoryData"],
  name: "compomemts_category",
  components: {
    selectDistributor
  },
  data() {
    return {
      distributorShow: false,
      distributorData: [],
      buttonTime: true,
      formData: {
        id: '',
        name: '',
        parentId: '',
        status: 1,
        materialCategoryIds: []
      },
      categorylist: [],
      rules: {
        name: [
          { required: true, message: "请输入分类名称", trigger: "blur" }
        ],
        materialCategoryIds: [
          { required: true, message: "请选择可用材料分类", trigger: "blur" }
        ]
      }
    }
  },
  watch: {
    categoryData(val) {
      if (val.id != undefined) {
        this.formData = val;
      } else {
        this.formData = {
          id: '',
          name: '',
          parentId: '',
          status: 1,
          materialCategoryIds: []
        };
      }
    },
  },
  created() {
    if (this.categoryData.id != undefined) {
      this.formData = this.categoryData;
    }
    this.getMaterialCategorylist();
  },
  methods: {
    getMaterialCategorylist() {
      this.$api.get(this, api.getMaterialCategorylist).then(res => {
        if (res.success) {
          this.categorylist = res.data;
        }
      });
    },
    handleDeleteDistributor(index) {
      this.distributorData.splice(index, 1);
    },
    cancel() {
      this.distributorShow = false;
    },
    disCancel() {
      this.$refs.selectDistributor.handleCancel()
    },
    submitData() {
      this.$refs['ruleForm'].validate((valid) => {
        if (!valid) {
          return false;
        } else {
          if (this.buttonTime) {
            this.buttonTime = false;
            if (this.formData.id == '') {
              delete this.formData.id;
              if (this.addtype == 1) {
                this.formData.parentId = this.managerId;
              }
              this.$api.post(this, api.addImagesType, this.formData).then(() => {
                this.$message({
                  message: "新增成功",
                });
                this.buttonTime = true;
                this.$emit("updateList");
              });
            } else {
              this.$api.put(this, api.updateImagesType, this.formData).then(() => {
                this.$message({
                  message: "更新成功",
                });
                this.buttonTime = true;
                this.$emit("updateList");
              });
            }
          }
        }
      });
    },
    deleteMaterial() {
      this.$confirm("此操作将删除改材质类型，是否继续？", "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning",
        center: true,
      }).then(() => {
        this.$api.delete(this, api.deleteMaterialCategory, { id: this.formData.id }).then(() => {
          this.$message({
            message: "删除成功",
          });
          this.$emit("updateList");
        });
      }).catch(() => {
        this.$message({
          type: "info",
          message: "已取消操作",
        });
      });
    },
  }
};
</script>

<style rel="stylesheet/scss" lang="scss" scoped>
</style>
