<template>
  <div>
    <div class="material_title_box">
      {{checkMsg==0?'新建材料':'编辑材料'}}
    </div>
    <div class="material_linq"></div>
    <div class="material_form_box">
      <el-form :model="formData" :rules="rules" label-width="20%" label-position="right" ref="formData">
        <el-form-item label="材料名称" prop="name">
          <el-input v-model="formData.name" placeholder="请输入材料名称" />
        </el-form-item>
        <el-form-item label="材料编码" prop="code">
          <el-input v-model="formData.code" placeholder="请输入材料编码" />
        </el-form-item>
        <el-form-item label="第三方工厂" prop="description">
          <el-input v-model="formData.thirdPartyFactoryId" placeholder="请输入材料描述" />
        </el-form-item>
        <el-form-item label="包装编码" prop="description">
          <el-input v-model="formData.boxCode" placeholder="请输入材料描述" />
        </el-form-item>
        <el-form-item label="排序" prop="sortNum">
          <el-input v-model="formData.sortNum" type="number" placeholder="请输入排序" />
        </el-form-item>
        <el-form-item label="关联货品" prop="commodityList">
          <el-button class="mini-search-btn" @click="itemsSelectShow()">新增</el-button>
          <div style="width: 500px;">
            <el-table :data="formData.commodityList" header-row-class-name="header-row" class="tableCenter">
              <el-table-column label="货品编码" align="center" prop="itemCode" width="100"></el-table-column>
              <el-table-column label="货品名称" align="center" prop="itemName" width="200"></el-table-column>
              <!-- <el-table-column label="上架状态" align="center" prop="saleStatus" :formatter="formatSaleStatus" width="180"></el-table-column> -->
              <el-table-column label="操作" width="100" align="center" fixed="right">
                <template slot-scope="scope">
                  <el-button class="mini-delete-btn" @click="handleDeleteGoodsItems(scope.$index)">删除
                  </el-button>
                </template>
              </el-table-column>
            </el-table>
          </div>
        </el-form-item>
        <el-form-item label="图片" prop="imageUrl">
          <el-upload :action="action" :headers="headers" list-type="picture-card" :limit="1" :on-exceed="handleExceed" :on-success="handleSuccess" :on-remove="handleRemove" :before-upload="beforeUpload" :file-list="fileList">
            <i slot="default" class="el-icon-plus"></i>
          </el-upload>
        </el-form-item>
        <el-form-item label="允许传图" style="margin-bottom: 0px;">
          <el-radio-group v-model="formData.allowUploadImage" size="mini">
            <el-radio label="0">不允许</el-radio>
            <el-radio label="1">允许</el-radio>
          </el-radio-group>
        </el-form-item>
        <el-form-item label="状态" style="margin-bottom: 0px;">
          <el-radio-group v-model="formData.status" size="mini">
            <el-radio label="0">启用</el-radio>
            <el-radio label="1">禁用</el-radio>
          </el-radio-group>
        </el-form-item>
      </el-form>
    </div>
    <div class="button_box">
      <div class="exchange_bit" @click="submitData()">保存</div>
      <div class="return_bit" @click="returnList()">返回</div>
    </div>
    <el-dialog title="添加货品" :modal-append-to-body="false" :close-on-click-modal="false" :visible="itemsShow" width="80%" :before-close="closehandleShow">
      <selectItem v-if="itemsShow" :selectItemsData="formData.commodity" :multipleChoice="true" :saleStatus="3" :goodsType="4" ref="selectGoodItems" @cancel="itemsShow = false" @submit="getItemsData"></selectItem>
    </el-dialog>
  </div>
</template>
<script src="https://gosspublic.alicdn.com/aliyun-oss-sdk-4.4.4.min.js"></script>
<script>
import { monthDay } from '@/utils/common'
import axios from 'axios'
import api from "@/api/allUrl";
import selectItem from "@/components/selectItem"
export default {
  props: ['categoryId', 'checkMsg', 'materialListId'],
  name: "materialAdd",
  components: {
    selectItem
  },
  data() {
    return {
      action: process.env.VUE_APP_API_URL + '/device/system/v1/web/admin/oss/sts',
      headers: {
        'Accept': 'application/json',
        'Access-Control-Allow-Origin': '*',
        'Content-Type': 'application/json',
        'Platform': 'GF60004',
        'Version': '1.0.0',
        'token': localStorage.getItem('token'),
        'Cache-Control': 'no-cache',
        'Pragma': 'no-cache'
      },
      formData: {
        sortNum: 0,
        allowUploadImage: '1',
        boxCode: '',
        categoryId: '',
        code: '',
        commodity: {},
        commodityList: [],
        content: '',
        imageUrl: '',
        name: '',
        status: '0',
        thirdPartyFactoryId: ''
      },
      listObj: {},
      rules: {
        sortNum: [
          { required: true, message: "请输入排序", trigger: "blur" }
        ],
        name: [
          { required: true, message: "请输入材料分类名称", trigger: "blur" },
          { max: 100, message: "最多展示100个字", trigger: "blur" },
        ],
        imageUrl: [
          { required: true, message: "请选择图片", trigger: "blur" }
        ],
        commodityList: [
          { required: true, message: "请选择货品", trigger: "blur" }
        ]
      },
      itemsShow: false
    }
  },
  created() {
    this.fileList = [];
    if (this.checkMsg == 1) {
      this.$api.get(this, api.getMaterialdetails, {
        id: this.materialListId
      }).then(res => {
        this.fileList = [{ name: 'food.jpeg', url: res.data.imageUrl }]
        this.formData = res.data;
        this.formData.commodityList = [{
          "goodsName": this.formData.commodity.goodsName,
          "itemCode": this.formData.commodity.itemCode,
          "itemName": this.formData.commodity.goodsName,
          "itemId": this.formData.commodity.id,
        }];
      });
    }

  },
  methods: {
    returnList() {
      this.$emit("returnList");
    },
    submitData() {
      this.$refs['formData'].validate(valid => {
        this.formData.categoryId = this.categoryId;
        this.formData.commodity = this.formData.commodityList[0];
        if (valid) {
          if (this.checkMsg == 0) {
            this.$api.post(this, api.addMaterialList, this.formData).then(res => {
              if (res.success) {
                this.$message({
                  message: "新增成功",
                });
                this.returnList();
              }
            });
          } else {
            this.$api.post(this, api.updateMaterialList, this.formData).then(res => {
              if (res.success) {
                this.$message({
                  message: "修改成功",
                });
                this.returnList();
              }
            });
          }
        }
      })
    },
    handleRemove(file) {
      this.formData.imageUrl = '';
      this.fileList = []
    },
    beforeUpload(file) {
      if (file.type != 'image/jpeg' && file.type != 'image/bmp' &&
        file.type != 'image/jpg' && file.type != 'image/jpeg' &&
        file.type != 'image/png' && file.type != 'image/gif') {
        this.$message.error('上传图片只能是bmp、jpg、jpeg、png、gif格式!')
        return false
      }
      const isLt2M = file.size / 1024 / 1024 < 5;
      if (!isLt2M) {
        this.$message.error('上传图片大小不能超过 5MB!');
        return isLt2M
      }
      const _self = this
      const fileName = file.uid
      this.listObj[fileName] = {}
      // 随机命名
      let random_name = _self.random_string(6) + '_' + new Date().getTime() + '.' + file.name.split('.').pop()

      axios({
        method: 'get',
        url: process.env.VUE_APP_API_URL + '/device/system/v1/web/admin/oss/sts',
        headers: {
          'Accept': 'application/json',
          'Access-Control-Allow-Origin': '*',
          'Content-Type': 'application/json',
          'Platform': 'GF60004',
          'Version': '1.0.0',
          'token': localStorage.getItem('token'),
          'Cache-Control': 'no-cache',
          'Pragma': 'no-cache'
        },
        withCredentials: false
      }).then(result => {
        const client = new OSS.Wrapper({
          region: result.data.data.region,
          accessKeyId: result.data.data.accessKeyId,
          accessKeySecret: result.data.data.accessKeySecret,
          stsToken: result.data.data.securityToken,
          bucket: result.data.data.bucketName,
          endpoint: result.data.data.endpoint,
          secure: true
        })
        // 上传
        client.multipartUpload('goods/' + monthDay(new Date()) + '/' + random_name, file, {
        }).then((results) => {
          return new Promise((resolve) => {
            _self.listObj[fileName] = {
              hasSuccess: false,
              uid: file.uid,
              width: this.width,
              height: this.height,
              url1: result.data.data.hostname + results.name,
              url: result.data.data.hostname + results.name + '?x-oss-process=image/resize,h_146,l_146'
            }
            this.formData.imageUrl = result.data.data.hostname + results.name;
            resolve(true)
          })
        }).catch((err) => {
          console.log(err)
        })
      })
    },
    handleSuccess(response, file) {
      const objKeyArr = Object.keys(this.listObj)
      for (let i = 0, len = objKeyArr.length; i < len; i++) {
        if (this.listObj[objKeyArr[i]].uid === file.uid) {
          this.listObj[objKeyArr[i]].hasSuccess = true
          return
        }
      }
    },
    handleExceed(files, fileList) {
      if ((files.length + fileList.length) > 1) {
        this.$message.error('最多上传1个文件');
        return false
      }
    },
    // 删除货品
    handleDeleteGoodsItems(index) {
      this.formData.commodityList.splice(index, 1);
    },
    // 获取选中货品
    getItemsData(val) {
      console.log("货品名称：", val);
      this.formData.commodityList = val;
      this.itemsShow = false;
    },
    closehandleShow() {
      this.itemsShow = false;
    },
    itemsSelectShow() {
      this.itemsShow = true;
    },
    formatSaleStatus(row, col, val) {
      switch (val) {
        case 1:
          return "未上架";
        case 2:
          return "审批中";
        case 3:
          return "已上架";
      }
    },
    // 随机生成文件名
    random_string(len) {
      len = len || 32;
      var chars = 'ABCDEFGHJKMNPQRSTWXYZabcdefhijkmnprstwxyz2345678';
      var maxPos = chars.length;
      var pwd = '';
      for (let i = 0; i < len; i++) {
        pwd += chars.charAt(Math.floor(Math.random() * maxPos));
      }
      return pwd;
    },
  },
};
</script>

<style rel="stylesheet/scss"  scoped>
/deep/.el-upload-list--picture-card .el-upload-list__item {
  width: 50px;
  height: 50px;
}
</style>
