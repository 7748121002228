<template>
  <div>
    <div class="content_box">
      <div class="heading_box">
        <div class="search_for_box">
          <el-button class="mini_add_btn" @click="addMaterial(0)">+ 新建</el-button>
          <el-button @click="batchDelete()">批量删除</el-button>
          <el-button @click="batchUpdate()">批量修改</el-button>
          <el-button @click="batchenableDisable(0)">批量启用</el-button>
          <el-button @click="batchenableDisable(1)">批量禁用</el-button>
        </div>
        <div class="search_for_box">
          <el-select size="mini" v-model="pageInfo.status" placeholder="状态" style="width: 160px;margin-right: 5px" @change="onSearch()" clearable>
            <el-option label="启用" :value="0"></el-option>
            <el-option label="禁用" :value="1"></el-option>
          </el-select>
          <el-input placeholder="请输入材料名称" v-model="pageInfo.name">
            <el-button slot="append" icon="el-icon-search" @click.prevent="onSearch()"></el-button>
          </el-input>
        </div>
      </div>
      <el-table ref="multipleSelect" :data="tableData" header-row-class-name="header-row" @select="select" @select-all="selectAll" @selection-change="handleSelectionChange">
        <el-table-column align="center" type="selection" width="50" :selectable="checkSelectable"></el-table-column>
        <el-table-column label="id" prop="id" width="50"></el-table-column>
        <el-table-column label="排序" align="center" prop="sortNum" width="50"></el-table-column>
        <el-table-column label="图片" align="center" prop="imageUrl">
          <template slot-scope="scope">
            <el-image style="width: 40px; height: 40px" :src="scope.row.imageUrl" fit="contain" :preview-src-list="[scope.row.imageUrl]">
            </el-image>
          </template>
        </el-table-column>
        <el-table-column label="材料名称" align="center" prop="name"></el-table-column>
        <el-table-column label="材料编码" align="center" prop="code"></el-table-column>
        <el-table-column label="包装编码" align="center" prop="boxCode"></el-table-column>
        <el-table-column label="关联货品" align="center" prop="commodity">
          <template slot-scope="scope">
            {{scope.row.commodity.goodsName}}
          </template>
        </el-table-column>
        <el-table-column label="第三方工厂" align="center" prop="thirdPartyFactoryId"></el-table-column>
        <el-table-column label="状态" align="center" prop="status">
          <template slot-scope="scope">
            <div :class="scope.row.status==1?'closed_status_box':'startup_status_box'" @click="enableDisable(scope.row)">
              {{scope.row.status==1?'禁用':'启用'}}
              <i class="el-icon-video-play" v-if="scope.row.status==1"></i>
              <i class="el-icon-video-pause" v-else></i>
            </div>
          </template>
        </el-table-column>
        <el-table-column label="操作" align="center" width="80">
          <template slot-scope="scope">
            <div class="operating_button_box">
              <div class="startup_status_box" @click="edit(scope.row.id)">编辑</div>
              <div class="closed_status_box" @click="deleteMaterialList(scope.row.id)">删除</div>
            </div>
          </template>
        </el-table-column>
      </el-table>
      <el-pagination :total="total" :page="pageInfo.page" @size-change="onSizeCHange" @current-change="onCurrentChange">
      </el-pagination>
      <el-dialog :visible="batchModifyShow" title="批量修改" width="560px" :before-close="closeDialog">
        <el-form :model="formData" :rules="rules" label-width="20%" label-position="right" ref="formData" style="margin-bottom: 40px;">
          <el-form-item label="材料编码" prop="code">
            <el-input v-model="formData.code" placeholder="请输入材料编码" />
          </el-form-item>
          <el-form-item label="包装编码" prop="boxCode">
            <el-input v-model="formData.boxCode" placeholder="请输入包装编码" />
          </el-form-item>
          <el-form-item label="第三方工厂" prop="thirdPartyFactoryId">
            <el-input v-model="formData.thirdPartyFactoryId" placeholder="请输入第三方工厂" />
          </el-form-item>
        </el-form>
        <div class="button_box">
          <div class="exchange_bit" @click="submitData()">保存</div>
          <div class="return_bit" @click="batchModifyShow=false">取消</div>
        </div>
      </el-dialog>
    </div>
  </div>
</template>
<script>
import api from "@/api/allUrl";
export default {
  props: ['categoryId'],
  name: "material_List",
  data() {
    return {
      pageInfo: {
        page: 1,
        size: 10,
        name: '',
        status: '',
        categoryId: '',
        modelCategoryId: ''
      },
      total: 0,
      tableData: [],
      isSelect: false,
      multipleSelect: [],
      batchModifyShow: false,
      formData: {
        boxCode: '',
        code: '',
        ids: [],
        thirdPartyFactoryId: ''
      },
      rules: {
        boxCode: [
          { required: true, message: "请输入包装编码", trigger: "blur" }
        ],
        code: [
          { required: true, message: "请输入材料编码", trigger: "blur" }
        ],
        thirdPartyFactoryId: [
          { required: true, message: "请输入第三方工厂", trigger: "blur" }
        ],
      },
    }
  },
  created() {
    this.getMaterialList();
  },
  watch: {
    categoryId: {
      // 请求的分销商数据变化时
      handler() {
        this.getMaterialList();
      },
      deep: true,
    },
  },
  methods: {
    closeDialog() {
      this.batchModifyShow = false;
    },
    batchUpdate() {
      if (this.multipleSelect.length == 0) {
        this.$message({
          message: "请选择需要改的材质",
        });
      } else {
        this.formData = {
          boxCode: '',
          code: '',
          ids: [],
          thirdPartyFactoryId: ''
        };
        this.batchModifyShow = true;
      }
    },
    submitData() {
      this.multipleSelect.forEach(item => {
        this.formData.ids.push(item.id);
      });
      this.$api.post(this, api.batchupdateMaterialList, this.formData).then(() => {
        this.$message({
          message: "更新成功",
        });
        this.closeDialog();
        this.getMaterialList();
      });
    },
    batchenableDisable(status) {
      if (this.multipleSelect.length == 0) {
        this.$message({
          message: "请选择需要改的材质",
        });
      } else {
        var title = "此操作将启用所选材质，是否继续？";
        var message = '启用成功';
        if (status == 1) {
          title = "此操作将禁用所选材质，是否继续？";
          message = '禁用成功';
        }
        this.$confirm(title, "提示", {
          confirmButtonText: "确定",
          cancelButtonText: "取消",
          type: "warning",
          center: true,
        }).then(() => {
          var ids = [];
          this.multipleSelect.forEach(item => {
            ids.push(item.id);
          });
          this.$api.post(this, api.batchEnableDisableMaterial, {
            ids: ids,
            status: status
          }).then(() => {
            this.$message({
              message: message,
            });
            this.getMaterialList();
          });
        }).catch(() => {
          this.$message({
            type: "info",
            message: "已取消操作",
          });
        });
      }
    },
    batchDelete() {
      this.$confirm("此操作将删除所选材质，是否继续？", "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning",
        center: true,
      }).then(() => {
        var ids = [];
        this.multipleSelect.forEach(item => {
          ids.push(item.id);
        });
        this.$api.delete(this, api.batchdeleteMaterialList, { ids: ids }).then(() => {
          this.$message({
            message: "删除成功",
          });
          this.getMaterialList();
        });
      }).catch(() => {
        this.$message({
          type: "info",
          message: "已取消操作",
        });
      });
    },
    edit(id) {
      this.$emit("editMaterial", id);
    },
    deleteMaterialList(id) {
      this.$confirm("此操作将删除该材质，是否继续？", "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning",
        center: true,
      }).then(() => {
        this.$api.delete(this, api.deleteMaterialList, { id: id }).then(() => {
          this.$message({
            message: "删除成功",
          });
          this.getMaterialList();
        });
      }).catch(() => {
        this.$message({
          type: "info",
          message: "已取消操作",
        });
      });
    },
    enableDisable(item) {
      var message = "是否确认启用该材质？";

      if (item.status == 0) {
        message = "是否确认禁用该材质？";
      }
      this.$confirm(message, "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning",
        center: true
      }).then(() => {
        if (item.status == 0) {
          item.status = 1;
        } else {
          item.status = 0;
        }
        this.$api.post(this, api.updateMaterialList, item).then(res => {
          if (res.success) {
            this.$message({
              message: "修改成功",
            });
            this.getMaterialList();
          }
        });
      }).catch(() => {
        // 处理取消操作
      });
    },
    addMaterial(checkMsg) {
      this.$emit("addMaterial", checkMsg);
    },
    onSearch() {
      this.pageInfo.page = 1;
      this.getMaterialList();
    },
    getMaterialList() {
      // this.pageInfo.modelCategoryId = this.categoryId;
      this.pageInfo.categoryId = this.categoryId;
      this.$api.get(this, api.getMaterialList, this.pageInfo).then(res => {
        if (res.success) {
          this.tableData = res.data.list;
          this.total = res.data.total;
          setTimeout(() => {
            this.multipleSelect.forEach(row1 => {//重新获取数据时，判断哪些选中了
              if (row1 != undefined) {
                this.tableData.forEach(row2 => {
                  if (row1.id == row2.id) {
                    this.$refs.multipleSelect.toggleRowSelection(row2);
                  }
                })
              }
            });
          }, 100)
        }
      });
    },
    // 优惠券条数
    onSizeCHange(size) {
      this.pageInfo.size = size;
      this.pageInfo.page = 1;
      this.getMaterialList();
    },
    // 优惠券页数
    onCurrentChange(page) {
      this.pageInfo.page = page;
      this.getMaterialList();
    },
    //去重
    setArr(arr) {
      let obj = {};
      let temp = [];
      for (let i = 0; i < arr.length; i++) {
        let type = Object.prototype.toString.call(arr[i].id);//不加类型 分不清 1 '1'    
        if (!obj[arr[i].id + type]) {
          temp.push(arr[i]);
          obj[arr[i].id + type] = true;//这里给true 利于代码阅读和判断。  如果给 0,'' ,false ,undefined 都会在if那里判断为 false 不利于代码阅读  
        }
      }
      return temp;
    },
    select(selection, row) {//单选时调用
      this.isSelect = true
      let d = false
      for (let i = 0; i < this.multipleSelect.length; i++) {
        if (this.multipleSelect[i].id === row.id) {
          this.multipleSelect.splice(i, 1)
          d = true
          break
        }
      }
      if (!d) {
        this.multipleSelect.push(row)
        this.multipleSelect = this.setArr(this.multipleSelect)
      }
    },
    selectAll(selection) {//全选时调用
      this.isSelect = true
      if (selection.length === 0) {
        this.tableData.forEach(row => {
          for (let i = 0; i < this.multipleSelect.length; i++) {
            if (this.multipleSelect[i].id === row.id) {
              this.multipleSelect.splice(i, 1)
              break
            }
          }
        })
      } else {
        this.multipleSelect = this.setArr(this.multipleSelect.concat(selection))
      }
    },
    handleSelectionChange(val) {//当切换页面时的作用
      if (val.length === 0 && this.multipleSelect.length != 0 && !this.isSelect) {
        this.multipleSelect.forEach(row1 => {
          this.tableData.forEach(row2 => {
            if (row1.id === row2.id) {
              this.$refs.multipleSelect.toggleRowSelection(row2);
            }
          })
        });
        this.isSelect = true
      }
    },
    checkSelectable(row) {
      return row.isDisabled !== 1
    },
  }
};
</script>

<style rel="stylesheet/scss" lang="scss" scoped>
.operating_button_box {
  display: flex;
  justify-content: space-between;
  align-items: center;
  cursor: pointer;
}
.closed_status_box {
  color: red;
  cursor: pointer;
}
.startup_status_box {
  color: #ff7500;
  cursor: pointer;
}
</style>
