<template>
  <div class="distributor_box">
    <div>
      <el-button style="float: right;margin-bottom: 10px;margin-left: 10px;" class="mini-search-btn" @click="filter">搜索</el-button>
      <el-input size="mini" style="width:20%;float: right;margin-bottom: 10px;line-height:0px;" v-model="content" placeholder="请输入关键词搜索"></el-input>
      <el-select size="mini" v-model="contentType" placeholder="分销商用户名" style="width: 140px;float:right;margin-right:5px;" clearable>
        <el-option label="分销商用户名" value="1"> </el-option>
        <el-option label="客户名称" value="2"> </el-option>
        <el-option label="分销商ID" value="3"> </el-option>
      </el-select>
    </div>
    <el-table ref="multipleSelect" :data="tableData" tooltip-effect="dark" @select="select" @select-all="selectAll" style="text-align:center;" header-row-class-name="header-row" @selection-change="handleSelectionChange" v-loading="loading">
      <el-table-column align="center" type="selection" with="100" :selectable="checkSelectable"></el-table-column>
      <el-table-column align="center" label="分销商用户名" prop="name"></el-table-column>
      <el-table-column align="center" label="B2B编码" prop="id"></el-table-column>
      <el-table-column align="center" label="是否直发" prop="autoDelivery">
        <template slot-scope="scope">
          <div v-if="scope.row.autoDelivery === 1">是</div>
          <div v-else>否</div>
        </template>
      </el-table-column>
      <el-table-column align="center" label="更新时间" prop="updateTime"></el-table-column>
    </el-table>
    <el-pagination :total="total" :page="pageInfo.page" @size-change="sizeChange" @current-change="currentChange">
    </el-pagination>
    <el-button style="margin-left: 46%;margin-top: 10px;" class="mini-search-btn" @click="handleSubmit">确定</el-button>
    <el-button size="mini" style="margin-top: 10px;" @click="handleCancel">返回</el-button>
  </div>
</template>
<script>
import api from "@/api/allUrl";
export default {
  props: ['distributorData', 'isFinancial', 'multipleChoice'],
  data() {
    return {
      pageInfo: {
        page: 1,
        size: 10,
        freezeStatus: 1,
        // profileStatus: 2
      },
      tableData: [],
      total: 0,
      multipleSelect: [],
      content: '',
      contentType: '',
      isSelect: false,
      selected: [],
      loading: false
    }
  },
  created() {
    this.multipleSelect = []
    this.multipleSelect = this.multipleSelect.concat(this.distributorData)
    this.getTableData();
  },
  methods: {
    checkSelectable(row) {
      return row.isDisabled !== 1
    },
    selectRow() {//分销商请求数据变化时，重新选择行（如，删除、数据变化）
      this.$refs.multipleSelect.clearSelection();
      this.multipleSelect.forEach(row1 => {
        this.tableData.forEach(row2 => {
          if (row1.id === row2.id) {
            this.$refs.multipleSelect.toggleRowSelection(row2);
          }
        })
      });
    },
    getTableData() {
      this.loading = true
      this.$api.get(this, api.getDistributorNPoList, this.pageInfo).then(res => {
        this.isSelect = false
        this.selected = []
        if (res.success) {

          this.tableData = res.data.list;
          this.total = res.data.total;
          this.multipleSelect.forEach(row1 => {//重新获取数据时，判断哪些选中了
            if (row1 != undefined) {
              this.tableData.forEach(row2 => {
                if (row1.id == row2.id) {
                  this.$refs.multipleSelect.toggleRowSelection(row2);
                  this.selected.push(row2)
                }
              })
            }
          });
        }
        res.success ? this.loading = false : this.loading = false
      })
    },
    handleSubmit() {
      this.$emit('submit', this.multipleSelect)
    },
    handleCancel() {
      this.multipleSelect = []
      this.multipleSelect = this.multipleSelect.concat(this.distributorData)
      this.$emit('cancel');
      this.selectRow()
    },
    select(selection, row) {//单选时调用
      this.isSelect = true
      if (this.multipleChoice != true) {
        let d = false
        for (let i = 0; i < this.multipleSelect.length; i++) {
          if (this.multipleSelect[i].id === row.id) {
            this.multipleSelect.splice(i, 1)
            d = true
            break
          }
        }
        if (!d) {
          this.multipleSelect.push(row)
          this.multipleSelect = this.setArr(this.multipleSelect)
        }
      } else {
        this.$refs.multipleSelect.clearSelection();
        this.multipleSelect = [row];
        this.multipleSelect = this.setArr(this.multipleSelect);
        this.$refs.multipleSelect.toggleRowSelection(row);
      }
    },
    selectAll(selection) {//全选时调用
      this.isSelect = true
      if (this.multipleChoice != true) {
        if (selection.length === 0) {
          this.tableData.forEach(row => {
            for (let i = 0; i < this.multipleSelect.length; i++) {
              if (this.multipleSelect[i].id === row.id) {
                this.multipleSelect.splice(i, 1)
                break
              }
            }
          })
        } else {
          this.multipleSelect = this.setArr(this.multipleSelect.concat(selection))
        }
      } else {
        this.$refs.multipleSelect.clearSelection();
        this.$refs.multipleSelect.toggleRowSelection(this.multipleSelect[0]);
      }
    },
    handleSelectionChange(val) {//当切换页面时的作用
      if (val.length === 0 && this.multipleSelect.length != 0 && !this.isSelect) {
        this.multipleSelect.forEach(row1 => {
          this.tableData.forEach(row2 => {
            if (row1.id === row2.id) {
              this.$refs.multipleSelect.toggleRowSelection(row2);
            }
          })
        });
        this.isSelect = true
      }
    },
    //去重
    setArr(arr) {
      let obj = {};
      let temp = [];
      for (let i = 0; i < arr.length; i++) {
        let type = Object.prototype.toString.call(arr[i].id);//不加类型 分不清 1 '1'    
        if (!obj[arr[i].id + type]) {
          temp.push(arr[i]);
          obj[arr[i].id + type] = true;//这里给true 利于代码阅读和判断。  如果给 0,'' ,false ,undefined 都会在if那里判断为 false 不利于代码阅读  
        }
      }
      return temp;
    },
    filter() {
      this.pageInfo.content = this.content
      this.pageInfo.contentType = this.contentType
      this.pageInfo.page = 1
      this.getTableData();
    },
    // 分页页数
    sizeChange(size) {
      this.pageInfo.size = size;
    },
    // 分页总数
    currentChange(page) {
      this.pageInfo.page = page;
    }
  },
  watch: {
    pageInfo: {
      handler() {
        this.getTableData()
      },
      deep: true
    },
    distributorData: {//请求的分销商数据变化时
      handler() {
        this.multipleSelect = []
        this.multipleSelect = this.multipleSelect.concat(this.distributorData)
        this.selectRow()
      },
      deep: true
    },
  }
}
</script>
<style lang="scss" scoped>
.distributor_box {
  background-color: white;
  min-height: 100%;

  .foot-btn {
    margin-top: 50px;
  }
}
</style>
