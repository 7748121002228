<template>
  <div>
    <div class="page_header_title_box">关键词列表</div>
    <div style="padding: 70px 20px 20px 20px;">
      <div class="content_box">
        <div class="heading_box">
          <div class="search_for_box">
            <el-button class="dd_btn">+ 新建</el-button>
            <el-button class="dd_btn">批量导入</el-button>
            <el-button class="dd_btn">批量删除</el-button>
            <el-button class="dd_btn">批量启用</el-button>
            <el-button class="dd_btn">批量禁用</el-button>
            <el-button class="dd_btn">批量推荐</el-button>
            <el-button class="dd_btn">批量取消推荐</el-button>
          </div>
          <div class="search_for_box search_for">
            <el-input placeholder="请输入框图ID">
              <el-button slot="append" icon="el-icon-search"></el-button>
            </el-input>
            <el-button class="dd_btn" style="margin-left: 10px;" @click="advanced=!advanced">
              <i class="el-icon-caret-bottom" v-if="advanced"></i>
              <i class="el-icon-caret-top" v-else></i>
              高级筛选
            </el-button>
            <el-button class="dd_btn">重置</el-button>
          </div>
        </div>
        <div class="heading_box" v-show="advanced">
          <div class="search_for_box"></div>
          <div class="search_for_box search_for">
            <el-select size="mini" placeholder="是否推荐" style="width: 100px" clearable>
              <el-option label="启用" :value="1"></el-option>
              <el-option label="禁用" :value="2"></el-option>
            </el-select>
            <el-select size="mini" placeholder="状态" style="width: 100px" clearable>
              <el-option label="启用" :value="1"></el-option>
              <el-option label="禁用" :value="2"></el-option>
            </el-select>
          </div>
        </div>
        <!-- @select="select" @select-all="selectAll" @selection-change="handleSelectionChange" -->
        <el-table ref="multipleSelect" :data="tableData" v-loading="loading">
          <el-table-column type="selection" width="50" align="center"></el-table-column>
          <el-table-column label="ID" align="center" prop=""></el-table-column>
          <el-table-column label="排序" align="center" prop=""></el-table-column>
          <el-table-column label="关键词" align="center" prop=""></el-table-column>
          <el-table-column label="是否推荐" align="center" prop=""></el-table-column>
          <el-table-column label="状态" align="center" prop=""></el-table-column>
          <el-table-column label="操作" align="center" fixed="right">
            <!-- <template slot-scope="scope">
              <el-button type="primary" @click="frequencyAdjustment(scope.row.deviceNo)">次数管理</el-button>
            </template> -->
          </el-table-column>
        </el-table>
        <el-pagination :total="total" :page="pageInfo.page" @size-change="onSizeCHange" @current-change="onCurrentChange">
        </el-pagination>
      </div>
    </div>
  </div>
</template>

<script>
// import api from "@/api/allUrl";
export default {
  name: "keyword_list",
  data() {
    return {
      tableData: [],
      loading: false,
      advanced: false,
      pageInfo: {
        page: 1,
        size: 10
      },
      total: 0
    }
  },
  created() {
  },
  methods: {
    onSizeCHange() { },
    onCurrentChange() { }
  }
};
</script>

<style rel="stylesheet/scss" lang="scss" scoped>
.el-select {
  margin-left: 5px;
}
</style>
