<template>
  <div>
    <div class="page_header_title_box" v-if="isShow">
      <i class="el-icon-back" @click="handleCancel"></i>
      新增设备
    </div>
    <div class="page_header_title_box" v-else>
      <i class="el-icon-back" @click="handleCancel"></i>编辑设备
    </div>
    <div style="padding: 70px 20px 20px 20px;">
      <div class="content_box">
        <el-form label-width="150px" :rules="rules" ref="formData" :model="formData" v-loading="vloading">
          <el-form-item label="设备类型" prop="deviceCategoryId">
            <el-select size="mini" v-model="formData.deviceCategoryId" placeholder="设备类型" style="width: 200px" clearable>
              <el-option v-for="item, index in devicetypeList" :key="index" :label="item.name" :value="item.id"></el-option>
            </el-select>
          </el-form-item>
          <el-form-item label="设备ID" prop="deviceNo">
            <el-input size="mini" style="width:500px;" v-model="formData.deviceNo" placeholder="请输入设备ID" maxlength="40">
            </el-input>
          </el-form-item>
          <el-form-item label="设备编码">
            <el-input size="mini" style="width:500px;" v-model="formData.deviceCode" placeholder="请输入设备编码" maxlength="40">
            </el-input>
          </el-form-item>
          <el-form-item label="设备状态">
            <el-radio-group v-model="formData.openFlag">
              <el-radio :label="1">启用</el-radio>
              <el-radio :label="0">禁用</el-radio>
            </el-radio-group>
          </el-form-item>
          <el-form-item label="APP版本号">
            <el-input size="mini" style="width:500px;" v-model="formData.appversion" placeholder="请输入APP版本号" maxlength="40">
            </el-input>
          </el-form-item>
          <el-form-item label="设备第三方编码">
            <el-input size="mini" style="width:500px;" v-model="formData.deviceThirdpartyNo" placeholder="请输入设备第三方编码" maxlength="40">
            </el-input>
          </el-form-item>
          <el-form-item label="固件版本">
            <el-input size="mini" style="width:500px;" v-model="formData.firmwareversion" placeholder="请输入固件版本" maxlength="40">
            </el-input>
          </el-form-item>
          <el-form-item label="主板版本">
            <el-input size="mini" style="width:500px;" v-model="formData.mainboardversion" placeholder="请输入主板版本" maxlength="40">
            </el-input>
          </el-form-item>
          <el-form-item label="设备描述">
            <el-input style="width:500px;" size="mini" type="textarea" :rows="4" v-model="formData.remark" placeholder="不超过200个字" maxlength="200"></el-input>
          </el-form-item>
          <el-form-item label="切割模式">
            <el-radio-group v-model="formData.cutCoupon">
              <el-radio :label="0">普通切割</el-radio>
              <el-radio :label="1">扫码切割</el-radio>
              <el-radio :label="2">普通+扫码切割</el-radio>
            </el-radio-group>
          </el-form-item>
          <el-form-item label="扫码链接" v-if="formData.cutCoupon != 0">
            <el-input size="mini" v-model="formData.couponUrl" style="width:500px;" placeholder="请输入">
            </el-input>
          </el-form-item>
          <el-form-item label="关联分销商">
            <el-button class="mini-search-btn" @click="distributorShow = true">选择分销商</el-button>
            <div style="width: 550px;margin-top: 10px">
              <el-table :data="distributorData" border header-row-class-name="header-row" max-height="600" class="tableCenter">
                <el-table-column align="center" label="分销商ID" width="120" prop="id"></el-table-column>
                <el-table-column align="center" label="分销商名称" width="320" prop="name"></el-table-column>
                <el-table-column align="center" label="操作">
                  <template slot-scope="scope">
                    <el-button style="margin-top:0px;margin-bottom:0px;" class="mini-delete-btn" @click="handleDeleteDistributor(scope.$index)">
                      删除
                    </el-button>
                  </template>
                </el-table-column>
              </el-table>
            </div>
          </el-form-item>
          <el-form-item label="店铺">
            <!-- <el-select size="mini" @change="selectShop" v-model="formData.shopId" placeholder="请选择店铺" style="width: 200px" clearable>
              <el-option v-for="item, index in shopList" :key="index" :label="item.shopName" :value="item.id"></el-option>
            </el-select> -->

            <el-autocomplete size="mini" style="width: 200px;" v-model="formData.shopName" :fetch-suggestions="querySearchshopAsync" placeholder="请输入店铺名称" @select="handleshopSelect"></el-autocomplete>

          </el-form-item>
          <el-form-item label="店铺名称" prop="name">
            <el-input size="mini" :disabled="formData.shopId != ''" style="width:300px;" v-model="formData.shopName" placeholder="请输入店铺名称" maxlength="40">
            </el-input>
          </el-form-item>
          <el-form-item label="联系人" prop="name">
            <el-input size="mini" :disabled="formData.shopId != ''" style="width:300px;" v-model="formData.name" placeholder="请输入联系人" maxlength="40">
            </el-input>
          </el-form-item>
          <el-form-item label="手机号" prop="name">
            <el-input size="mini" :disabled="formData.shopId != ''" style="width:300px;" v-model="formData.phone" placeholder="请输入手机号" maxlength="40">
            </el-input>
          </el-form-item>
          <el-form-item label="地区" prop="name">
            <el-select :disabled="formData.shopId != ''" v-model="address.provinceName" placeholder="请选择" size="mini" filterable @change="provinceIdChange($event)">
              <el-option v-for="province in this.RegionForChose.provinceId" :key="province.id" :label="province.regionName" :value="province.id"> </el-option>
            </el-select>
            <el-select :disabled="formData.shopId != ''" v-model="address.cityName" v-if="cityShow" placeholder="请选择" size="mini" filterable @change="cityIdChange($event)">
              <el-option v-for="city in this.RegionForChose.cityId" :key="city.id" :label="city.regionName" :value="city.id">
              </el-option>
            </el-select>
            <el-select :disabled="formData.shopId != ''" v-model="address.districtName" v-if="townShow" placeholder="请选择" size="mini" filterable>
              <el-option v-for="town in this.RegionForChose.districtId" :key="town.id" :label="town.regionName" :value="town.id" @change="districtIdChange($event)">
              </el-option>
            </el-select>
          </el-form-item>
          <el-form-item label="详细地址" prop="name">
            <el-input :disabled="formData.shopId != ''" size="mini" style="width:300px;" v-model="formData.address" placeholder="请输入详细地址" maxlength="40">
            </el-input>
          </el-form-item>
        </el-form>
        <el-dialog :modal-append-to-body="false" :visible="distributorShow" :before-close="disCancel" width="80%">
          <selectDistributor :distributorData="distributorData" :multipleChoice="true" ref="selectDistributor" @cancel="cancel" @submit="disSubmit">
          </selectDistributor>
        </el-dialog>
        <div class="clearfix footbtn">
          <el-button type="primary" :loading="loading" style="margin-left: 46.5%;" @click="handleSubmit()">保存</el-button>
          <el-button @click="handleCancel()">返回</el-button>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import selectDistributor from "@/components/distributorList"
import api from "@/api/allUrl";
export default {
  name: "add_device",
  components: {
    selectDistributor
  },
  data() {
    return {
      vloading: false,
      loading: false,
      isShow: true,
      distributorData: [],
      formData: {
        couponUrl: '',
        cutCoupon: 0,
        phone: '',
        shopName: '',
        name: '',
        address: '',
        deviceThirdpartyNo: '',
        distributorId: '',
        firmwareversion: '',
        appversion: '',
        mainboardversion: '',
        openFlag: 1,
        shopId: '',
        remark: '',
        deviceNo: '',
        deviceCategoryId: '',//设备类型id
        companyName: '',
        distributorName: '',
        countryId: 37, // 国家id
        countryName: '中国', // 国家名称
        provinceId: '', // 省份id
        provinceName: '',//省份名称
        cityId: '', // 城市id
        cityName: '',//城市名称
        districtId: '', // 区id
        districtName: '',//区名称
        shopCode: '',
        deviceCode: ''
      },
      address: {
        countryId: 37, // 国家id
        countryName: '中国', // 国家名称
        provinceId: '', // 省份id
        provinceName: '',//省份名称
        cityId: '', // 城市id
        cityName: '',//城市名称
        districtId: '', // 区id
        districtName: '',//区名称
        shopCode: ''
      },
      RegionForChose: { // 被选region
        countryId: [],
        provinceId: [],
        cityId: [],
        districtId: []
      },
      cityShow: false,
      townShow: false,
      distributorShow: false,
      rules: {
        deviceCategoryId: [{
          required: true,
          message: '请选择设备类型',
          trigger: 'blur'
        }],
        deviceNo: [{
          required: true,
          message: '请输入设备号',
          trigger: 'blur'
        }]
      },
      shopList: [],
      devicetypeList: []
    }
  },
  created() {
    this.vloading = true;
    this.distributorData = [];
    this.formData = {
      cutCoupon: 0,
      couponUrl: '',
      phone: '',
      shopName: '',
      name: '',
      address: '',
      deviceThirdpartyNo: '',
      distributorId: '',
      firmwareversion: '',
      appversion: '',
      mainboardversion: '',
      openFlag: 1,
      shopId: '',
      remark: '',
      deviceNo: '',
      deviceCategoryId: '',//设备类型id
      deviceCode: '',
      countryId: 37, // 国家id
      countryName: '中国', // 国家名称
      provinceId: '', // 省份id
      provinceName: '',//省份名称
      cityId: '', // 城市id
      cityName: '',//城市名称
      districtId: '', // 区id
      districtName: '',//区名称
    };
    this.address = {
      countryId: 37, // 国家id
      countryName: '中国', // 国家名称
      provinceId: '', // 省份id
      provinceName: '',//省份名称
      cityId: '', // 城市id
      cityName: '',//城市名称
      districtId: '', // 区id
      districtName: '',//区名称
    };
    this.RegionForChose = { // 被选region
      countryId: [],
      provinceId: [],
      cityId: [],
      districtId: []
    };
    this.shopList = [];
    this.devicetypeList = [];
    //获取设备类型
    this.getDevicetypeList();
    if (this.$route.query.checkMsg == 3) {
      this.isShow = false;
      this.getDevicedetails();
    } else {
      this.isShow = true;
      this.vloading = false;
    }
  },
  methods: {
    handleshopSelect(item) {
      this.formData.shopId = item.id;
      this.selectShop(item.id);
    },
    querySearchshopAsync(queryString, cb) {
      this.formData.shopName = queryString;
      let pageInfo = {
        content: queryString,
        openFlag: 1,
        page: 1,
        size: 10,
        distributorId: this.formData.distributorId
      }
      this.$api.get(this, api.shopList, pageInfo).then(res => {
        if (res.success) {
          var restaurants = []
          res.data.list.forEach(item => {
            restaurants.push({
              "value": item.shopName,
              "id": item.id,
            })
          })
        }
        cb(restaurants)
      })
    },
    selectShop(e) {
      this.formData.shopName = '';
      this.formData.phone = '';
      this.formData.name = '';
      this.formData.name = '';
      this.formData.address = '';
      this.shopList.forEach(item => {
        if (item.id == e) {
          this.formData.address = item.address;
          this.formData.shopName = item.shopName;
          this.formData.phone = item.phone;
          this.formData.name = item.name;
          this.address = {
            shopCode: item.shopCode,
            countryId: 37, // 国家id
            countryName: '中国', // 国家名称
            provinceId: item.provinceId, // 省份id
            provinceName: item.provinceName,//省份名称
            cityId: item.cityId, // 城市id
            cityName: item.cityName,//城市名称
            districtId: item.districtId, // 区id
            districtName: item.districtName,//区名称
          };
          if (this.address.cityId != undefined && this.address.cityId != '' || this.address.districtId != undefined && this.address.districtId != '') {
            this.cityShow = true;
            this.townShow = true;

            // this.$http.regionListById(this, {
            //   page: 1,
            //   size: 200,
            //   parentId: this.address.provinceId
            // }).then(res => {
            //   if (res.success) {
            //     this.cityShow = true
            //     this.RegionForChose.cityId = res.data.list

            //     if (res.data.list[0].haveNext === 1) {
            //       this.$http.regionListById(this, {
            //         page: 1,
            //         size: 200,
            //         parentId: this.address.cityId
            //       }).then(res => {
            //         this.RegionForChose.districtId = res.data.list
            //       })
            //       this.townShow = true
            //     } else {
            //       this.townShow = false
            //       this.address.districtId = 0
            //     }
            //   }
            // })
          }
        }
      });
    },
    getDevicedetails() {
      this.$api.get(this, api.getDevicedetails, {
        id: this.$route.query.id
      }).then(res => {
        this.formData = {
          cutCoupon: res.data.cutCoupon,
          couponUrl: res.data.couponUrl || '',
          deviceThirdpartyNo: res.data.deviceThirdpartyNo,
          distributorId: '',
          firmwareversion: res.data.firmwareversion,
          appversion: res.data.appversion,
          mainboardversion: res.data.mainboardversion,
          openFlag: res.data.openFlag,
          id: res.data.id,
          remark: res.data.remark,
          deviceNo: res.data.deviceNo,
          deviceCategoryId: res.data.deviceCategoryId,
          deviceCode: res.data.deviceCode
        };
        if (res.data.shopId != undefined) {
          this.formData.shopId = res.data.shopId || '';
          this.formData.shopName = res.data.shopName || '';
          this.formData.phone = res.data.phone || '';
          this.formData.name = res.data.name || '';
          this.formData.address = res.data.address || '';
          this.formData.shopCode = res.data.shopCode || '';

          this.address = {
            countryId: 37, // 国家id
            countryName: '中国', // 国家名称
            provinceId: res.data.provinceId, // 省份id
            provinceName: res.data.provinceName,//省份名称
            cityId: res.data.cityId, // 城市id
            cityName: res.data.cityName,//城市名称
            districtId: res.data.districtId, // 区id
            districtName: res.data.districtName,//区名称
          };
          if (this.address.cityId != undefined && this.address.cityId != '' || this.address.districtId != undefined && this.address.districtId != '') {
            this.cityShow = true;
            this.townShow = true;
          }

        }
        if (res.data.distributorId != undefined) {
          this.formData.distributorId = res.data.distributorId;
          this.formData.companyName = res.data.companyName;
          this.formData.distributorName = res.data.distributorName;
          // this.distributorData = [res.data.distributor];
          // this.distributorData[0].name = res.data.distributorName;
          this.distributorData = [{
            companyName: res.data.companyName,
            cutPackageFlag: 1,
            name: this.formData.distributorName,
            id: this.formData.distributorId,
          }];
          this.getShopList(this.formData.distributorId);
        }
        this.vloading = false;
      })
    },
    // 必填验证
    handleSubmit() {
      this.dloading = true;
      this.$refs['formData'].validate(valid => {
        if (valid) {
          var data = {};
          data = { ...this.formData, ...this.address };
          if (this.formData.cutCoupon == 0) {
            data.couponUrl = '';
          }
          if (this.formData.id == '' || this.formData.id == undefined) {
            this.$api.post(this, api.addDevice, data).then(res => {
              if (res.success) {
                this.$message.success({
                  message: "新增设备成功",
                  duration: 3 * 1000,
                })
                this.handleCancel();
              }
              this.dloading = false;
            })
          } else {
            this.$api.put(this, api.updateDevicedetails, data).then(res => {
              if (res.success) {
                this.$message.success({
                  message: "更新设备成功",
                  duration: 3 * 1000,
                })
                this.handleCancel();
              }
              this.dloading = false;
            })
          }
        } else {
          this.$message({
            message: '请先将设备信息补充完整',
            type: 'error',
            duration: 3 * 1000,
            onClose: () => {
            }
          })
          this.dloading = false;
        }
      })
    },
    provinceIdChange(event) { // 省
      this.address.provinceName = this.RegionForChose.provinceId.filter(item => item.id === event)[0].regionName;

    },
    cityIdChange(event) {
      console.log(event);

    },
    districtIdChange(event) {
      this.address.regionName = this.RegionForChose.districtId.filter(item => item.id === event)[0].regionName;
    },
    handleDeleteDistributor(index) {
      this.distributorData.splice(index, 1);
      this.formData.companyName = '';
      this.formData.distributorName = '';
      this.shopList = [];
      this.formData.shopId = '';
      this.formData.distributorId = '';
    },
    getShopList(id) {
      this.$api.get(this, api.getShopList, {
        content: id,
        openFlag: 1,
        page: 1,
        size: 10
      }).then(res => {
        this.shopList = res.data.list;
      });
    },
    disSubmit(msg) {
      this.distributorData = msg;
      this.formData.distributorId = msg[0].id;
      this.formData.companyName = msg[0].companyName;
      this.formData.distributorName = msg[0].name;
      this.distributorShow = false;
      this.getShopList(msg[0].id);
    },
    cancel() {
      this.distributorShow = false;
    },
    disCancel() {
      this.$refs.selectDistributor.handleCancel()
    },
    handleCancel() {
      this.$router.go(-1)
    },

    // 获取设备类型
    getDevicetypeList() {
      this.$api.get(this, api.getDevicetypeList, {
        page: 1,
        size: 100,
      }).then(res => {
        this.devicetypeList = res.data.list;
      })
    },
  }
};
</script>
<style lang="scss" scoped>
</style>