<template>
  <div>
    <div class="page_header_title_box">设备类型列表</div>
    <div style="padding: 70px 20px 20px 20px;">
      <div class="content_box">
        <div class="heading_box">
          <div class="search_for_box">
            <el-button class="mini_add_btn" @click="handlePlatform(1)">+ 设备类型</el-button>
          </div>
          <div class="search_for_box">
            <el-select class="mini_select_bit" size="mini" v-model="pageInfo.contentType" placeholder="请选择" style="width: 180px;margin-right: 5px;" clearable>
              <el-option label="设备类型ID" :value="1"></el-option>
              <el-option label="设备类型名称" :value="2"></el-option>
            </el-select>
            <el-input placeholder="请输入内容" v-model="pageInfo.content" @keyup.enter.native="onSearch()">
              <el-button slot="append" icon="el-icon-search" @click.prevent="onSearch()"></el-button>
            </el-input>
          </div>
        </div>
        <el-table :data="devicetypeList" v-loading="loading">
          <el-table-column label="设备类型ID" align="center" prop="id"></el-table-column>
          <el-table-column label="设备类型名称" align="center" prop="name"></el-table-column>
          <el-table-column label="第三方编码" align="center" prop="thirdPartyCode"></el-table-column>
          <el-table-column label="描述" align="center" prop="remark"></el-table-column>
          <el-table-column align="center" label="设备状态">
            <template slot-scope="scope">
              {{ scope.row.openFlag == 1 ? '启用' : '停用' }}
            </template>
          </el-table-column>
          <el-table-column label="操作" align="center">
            <template slot-scope="scope">
              <el-button type="warning" @click="handlePlatform(3, scope.row.id)">编辑
              </el-button>
              <el-button type="danger" @click="handleDelete(scope.row)">删除
              </el-button>
            </template>
          </el-table-column>
        </el-table>
        <el-pagination :total="total" :page="pageInfo.page" @size-change="onSizeCHange" @current-change="onCurrentChange">
        </el-pagination>
      </div>
    </div>
  </div>
</template>
<script>
import api from "@/api/allUrl";
export default {
  name: "deviceType",
  data() {
    return {
      loading: false,
      total: 1,
      pageInfo: {
        page: 1,
        size: 10,
        content: '',
        contentType: undefined
      },
      devicetypeList: []
    }
  },
  created() {
    this.loading = true;
    this.getDevicetypeList()
  },
  methods: {
    getDevicetypeList() {
      this.$api.get(this, api.getDevicetypeList, this.pageInfo).then(res => {
        this.devicetypeList = res.data.list;
        this.total = res.data.total;
        this.loading = false;
      })
    },
    onSearch() {
      this.loading = true;
      this.pageInfo.page = 1;
      this.getDevicetypeList();
    },
    handleDelete(row) { // 删除按钮
      if (row.openFlag == 1) {
        this.$message({
          type: "info",
          message: "该设备启用中"
        });
      } else {
        this.$confirm("此操作将永久删除该设备，是否继续？", "提示", {
          confirmButtonText: "确定",
          cancelButtonText: "取消",
          type: "warning",
          center: true
        }).then(res => {
          console.log(res);
          this.$api.delete(this, api.deleteDevicetypedetails, { id: row.id }).then(res => {
            if (res.success) {
              this.$message.success({
                message: "删除成功",
                duration: 3 * 1000,
              });
              this.pageInfo.page = 1;
              this.pageInfo.content = '';
              this.pageInfo.contentType = undefined;
              this.loading = true;
              this.getDevicetypeList();
            }
          })
        }).catch(res => {
          console.log(res);
          this.$message({
            type: "info",
            message: "已取消删除"
          });
        });
      }
    },
    // 添加/查看/编辑 - （1，添加，3，查看/编辑）
    handlePlatform(checkMsg, id) {
      var query = {};
      if (id) {
        query = {
          checkMsg: checkMsg,
          id: id,
        };
      } else {
        query = {
          checkMsg: checkMsg,
        };
      }
      this.$router.push({
        name: "add_devicetype",
        query: query,
      });
    },

    onSizeCHange(val) {
      // 分页方法
      this.loading = true;
      this.pageInfo.size = val;
      this.pageInfo.page = 1;
      this.pageInfo.content = '';
      this.pageInfo.contentType = undefined;
      this.getDevicetypeList();
    },
    onCurrentChange(val) {
      // 分页方法
      this.loading = true;
      this.pageInfo.page = val;
      this.getDevicetypeList();
    },
  }
};
</script>

<style rel="stylesheet/scss" lang="scss" scoped>
</style>
