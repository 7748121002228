
import axios from 'axios'
import { Message,MessageBox} from 'element-ui'
const service = axios.create({
  baseURL: process.env.VUE_APP_API_URL,
  timeout: 60000,
  headers: {
    'Accept': 'application/json',
    'Access-Control-Allow-Origin': '*',
    'Content-Type': 'application/json',
    'Platform': 'GF60004',
    'Version': '1.0.0',
    'token': '',
    'Cache-Control': 'no-cache',
    'Pragma': 'no-cache',
    'tenantNo': 100,
    'value': 100,
  },
  withCredentials: false
})

service.interceptors.request.use(config => {
  // config.headers['distributorId'] = localStorage.getItem('distributionId');
    if (localStorage.getItem('token')) {
      config.headers['token'] =localStorage.getItem('token');
    }
  return config
}, error => {
  console.log(error) 
  Promise.reject(error)
})
service.interceptors.response.use(
  response => {
    const res = response.data
    const headers = response.headers

     const token = headers['token']
    if (token !== undefined) {
      localStorage.setItem('token',token);
    }
    if (headers['content-type'] === 'application/octet-stream;charset=utf-8' || headers['content-type'] === 'application/vnd.ms-excel;charset=utf-8') {
      return response.data
    } else {
      //  console.log("店铺1:",res);
      if (res.success !== undefined) {
        if (res.success) {
          return res
        } else {
          Message({
            message: res.errMessage,
            type: 'error',
            duration: 3 * 1000
          })
          return res
        }
      } 
    }
  },
  // 对返回的错误进行一些处理
  error => {
  
        // 对响应错误做些什么，比如统一处理错误状态码
    if (error.response) {
      // 服务器返回了一个状态码，并且状态码触发了错误
      const status = error.response.status;
      const data = error.response.data;
      // 根据状态码执行特定的逻辑
      switch (status) {
        case 401:
       MessageBox.confirm('登录已失效，请重新登录', '确定登出', {
          confirmButtonText: '重新登录',
          cancelButtonText: '取消',
          showClose: false,
          showCancelButton: false, // 取消按钮隐藏
          closeOnClickModal: false, // 禁止点击遮罩层关闭提示
          type: 'warning'
       }).then(() => {
              localStorage.removeItem('token')
              window.location.href = '/';
        })

          break;
        case 403:
          // 禁止访问，显示禁止访问的提示
          // window.location.href = '/';
              console.error('Error Data:', data);
          break;
        case 404:
          // 未找到资源，显示未找到的提示或重定向到404页面
          // window.location.href = '/';
              console.error('Error Data:', data);
          break;
        // 处理其他状态码...
        default:
          // 对于其他状态码，可以显示一个通用的错误消息
          console.error('Error Status:', status);
          console.error('Error Data:', data);
          // alert('An error occurred. Please try again later.');
      }
    } else if (error.request) {
      // 请求已经发出，但没有收到响应（可能是网络问题）
      console.error('No response received:', error.request);
      alert('No response received. Please check your network connection.');
    } else {
      // 在设置请求时发生了错误（比如传入了一个无效的 URL）
      console.error('Error Message:', error.message);
      alert('An error occurred while setting up the request.');
    }
    return Promise.reject(error)
  }
)

export default service
