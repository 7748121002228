<template>
  <div>
    <div class="page_header_title_box">
      膜码列表
      <el-button class="mini_add_btn" @click="packingShow = true">生成装箱码</el-button>
      <el-button class="mini_add_btn" @click="addpuls()">导入膜码</el-button>
      <el-button class="mini_add_btn" @click="exportMembraneCode()">导出膜码</el-button>
      <el-button class="mini_add_btn" @click="addMembraneCode()">新增膜码</el-button>
      <el-button class="mini_add_btn" @click="unbind('')">批量解绑</el-button>
      <el-button class="mini_add_btn" @click="binding('')">批量绑定</el-button>
      <el-button class="mini_add_btn" @click="codeType()">批量更改类型</el-button>
    </div>
    <div style="padding: 70px 20px 20px 20px;">
      <div class="content_box">
        <div class="heading_box">
          <div class="search_for_box">
            <el-select v-model="pageInfo.filmCategoryId" size="mini" placeholder="膜品类型" style="width: 100px" clearable>
              <el-option v-for="item, index in membranecodeList" :key="index" :label="item.name" :value="item.id"></el-option>
            </el-select>
            <el-select size="mini" v-model="pageInfo.filmType" placeholder="膜码类型" style="width: 100px" clearable>
              <el-option label="通用膜码" :value="2"></el-option>
              <el-option label="专用膜码" :value="1"></el-option>
            </el-select>
            <el-select size="mini" v-model="pageInfo.exchangeStatus" placeholder="兑换状态" style="width: 100px" clearable>
              <el-option label="已兑换" :value="1"></el-option>
              <el-option label="未兑换" :value="0"></el-option>
            </el-select>
            <el-select size="mini" placeholder="膜码状态" v-model="pageInfo.openFlag" style="width: 100px;margin-right: 5px;" clearable>
              <el-option label="启用" :value="1"></el-option>
              <el-option label="禁用" :value="0"></el-option>
            </el-select>
            <el-date-picker size="mini" v-model="pageInfo.time" style="width: 330px" type="datetimerange" value-format="timestamp" range-separator="至" start-placeholder="生成开始日期" end-placeholder="生成结束日期"></el-date-picker>
          </div>
          <div class="search_for_box">
            <el-select size="mini" v-model="pageInfo.contentType" placeholder="请选择" style="width: 140px;margin-right: 5px;" clearable>
              <el-option label="膜码" :value="1"></el-option>
              <el-option label="设备号" :value="2"></el-option>
            </el-select>
            <el-input placeholder="请输入内容" v-model="pageInfo.content" @keyup.enter.native="onSearch()">
              <el-button slot="append" icon="el-icon-search" @click.prevent="onSearch()"></el-button>
            </el-input>
          </div>
        </div>
        <el-table ref="multipleSelect" :data="tableData" header-row-class-name="header-row" class="tableCenter" v-loading="loading" @select="select" @select-all="selectAll" @selection-change="handleSelectionChange">
          <el-table-column type="selection" width="50" align="center"></el-table-column>
          <el-table-column label="ID" align="center" prop="id" width="140"></el-table-column>
          <el-table-column label="膜码" align="center" prop="filmCode"></el-table-column>
          <el-table-column label="膜品类型" align="center" prop="filmCategoryName" width="200"></el-table-column>
          <el-table-column label="膜码类型" align="center" prop="filmType" width="200">
            <template slot-scope="scope">
              {{ scope.row.filmType == 1 ? '专用膜码' : '通用膜码' }}
            </template>
          </el-table-column>
          <el-table-column label="切膜次数" align="center" prop="count" width="100"></el-table-column>
          <el-table-column label="赠送次数" align="center" prop="reCount" width="100"></el-table-column>
          <el-table-column label="分销商" align="center" prop="distributorName"></el-table-column>
          <el-table-column label="兑换状态" align="center" prop="exchangeStatus" width="100">
            <template slot-scope="scope">
              {{ scope.row.exchangeStatus == 1 ? '已兑换' : '未兑换' }}
            </template>
          </el-table-column>
          <el-table-column label="兑换设备ID" align="center" prop="deviceNo" width="120"></el-table-column>
          <el-table-column label="膜码状态" align="center" prop="openFlag" width="100">
            <template slot-scope="scope">
              {{ scope.row.openFlag == 1 ? '启用' : '禁用' }}
            </template>
          </el-table-column>
          <el-table-column label="生成时间" align="center" prop="createTime" width="220"></el-table-column>
          <el-table-column label="兑换时间" align="center" prop="exchangeTime" width="220"></el-table-column>
          <el-table-column label="备注" align="center" prop="remark" width="220"></el-table-column>

          <el-table-column label="操作" align="center" fixed="right" width="340">
            <template slot-scope="scope">
              <div v-if="scope.row.exchangeStatus != 1">
                <el-button class="mini-search-btn" v-if="scope.row.distributorId == undefined" @click="binding(scope.row.filmCode)">绑定分销商</el-button>
                <el-button type="warning" @click="openDevice(scope.row)">
                  {{scope.row.openFlag== 1 ? '禁用' : '启用' }}
                </el-button>
                <el-button type="success" v-if="scope.row.exchangeStatus == 0 && scope.row.openFlag
                                    == 1 && scope.row.distributorId != undefined" @click="exchangeMembraneCode(scope.row)">兑换</el-button>
                <el-button type="danger" v-if="scope.row.distributorId != '' && scope.row.distributorId != undefined" class="mini-delete-btn" @click="unbind(scope.row.filmCode)">清除绑定</el-button>
                <el-button type="danger" @click="handleDelete(scope.row)">删除</el-button>
              </div>
              <div v-else>-</div>
            </template>
          </el-table-column>
        </el-table>
        <el-pagination :total="total" :page="pageInfo.page" @size-change="onSizeCHange" @current-change="onCurrentChange">
        </el-pagination>
      </div>
    </div>
    <!-- 膜码兑换 -->
    <el-dialog :visible="lookLogShow" width="50%" :title="deviceShow == false ? '膜码兑换' : '选择兑换设备'" :center="true" :before-close="closeLog">
      <div v-if="deviceShow == false">
        <div class="device_data_box">
          <div>
            <div>专用次数 <span>{{ cuttingCount }}</span></div>
            <div style="margin-top: 10px;">赠送次数 <span>{{ reCount }}</span></div>
          </div>
          <div></div>
        </div>
        <el-form ref="form" label-width="90px">
          <el-form-item label="兑换设备：">
            <el-button class="mini-search-btn" @click="deviceShow = true">选择设备</el-button>
            <div v-if="deviceNo != ''">已选设备：{{ deviceNo }}<span class="clean_up" @click="deviceNo = ''">清除</span>
            </div>
          </el-form-item>
        </el-form>
        <div class="button_box">
          <div class="exchange_bit" @click="confirmExchange()">确认兑换</div>
          <div class="return_bit" @click="closeLog()">返回</div>
        </div>
      </div>
      <div v-else>
        <div class="device_box">
          <el-input style="width: 200px;" placeholder="请输入内容" v-model="device.content" @keyup.enter.native="searchDeviceData()">
            <el-button slot="append" icon="el-icon-search" @click.prevent="searchDeviceData()"></el-button>
          </el-input>
        </div>
        <div>
          <el-table :data="deviceData" max-height="600" style="width: 100%" header-row-class-name="header-row" class="tableCenter">
            <el-table-column align="center" min-width="55" label="选择">
              <template slot-scope="scope">
                <!-- 可以手动的修改label的值，从而控制选择哪一项 -->
                <!-- 注意：这里写 &nbsp 的目的是为了页面不显示内容，只显示单选操作 -->
                <el-radio class="radio" v-model="deviceNo" :label="scope.row.deviceNo">&nbsp;</el-radio>
              </template>
            </el-table-column>
            <el-table-column align="center" label="设备ID" prop="deviceNo" width="100"></el-table-column>
            <el-table-column label="设备类型" align="center" prop="deviceCategoryName"></el-table-column>
            <el-table-column align="center" label="设备状态" width="100">
              <template slot-scope="scope">
                {{ scope.row.openFlag == 1 ? '启用' : '禁用' }}
              </template>
            </el-table-column>
            <el-table-column label="关联分销商/ID" align="center" prop="saleName" show-overflow-tooltip>
              <template slot-scope="scope">
                {{ scope.row.distributor != undefined
                                    ? scope.row.distributor.distributorName + '/' + scope.row.distributor.id : '-' }}
              </template>
            </el-table-column>
            <el-table-column label="店铺名称/联系人/手机号/地址" align="center" show-overflow-tooltip>
              <template slot-scope="scope">
                <div v-if="scope.row.shop != undefined">
                  <span v-if="scope.row.shop.shopName">{{ scope.row.shop.shopName }}</span>
                  <span v-if="scope.row.shop.name">/{{ scope.row.shop.name }}</span>
                  <span v-if="scope.row.shop.phone">/{{ scope.row.shop.phone }}</span>
                  <span v-if="scope.row.shop.address">/{{ scope.row.shop.address }}</span>
                </div>
                <div v-else>-</div>
              </template>
            </el-table-column>
          </el-table>
          <el-pagination :total="logTotal" :page="device.page" @sizeChange="onLogSizeCHange" @currentChange="onLogCurrentChange"></el-pagination>
        </div>
        <div class="button_box">
          <div class="exchange_bit" @click="confirmDevice()">确认</div>
          <div class="return_bit" @click="returnDevice()">返回</div>
        </div>
      </div>
    </el-dialog>
    <!-- 生成膜码 -->
    <el-dialog :visible="generateShow" width="50%" title="生成膜码" :center="true" :before-close="generatecloseLog">
      <div>
        <el-form ref="form" label-width="90px">
          <el-form-item label="膜码分类">
            <el-select size="mini" v-model="filmCategoryId" placeholder="膜码分类" style="width: 200px" clearable>
              <el-option v-for="item, index in membranecodeList" :key="index" :label="item.name" :value="item.id"></el-option>
            </el-select>
          </el-form-item>
          <el-form-item label="膜码类型">
            <el-select size="mini" v-model="filmType" placeholder="膜码类型" style="width: 100px" clearable>
              <el-option label="通用膜码" :value="2"></el-option>
              <el-option label="专用膜码" :value="1"></el-option>
            </el-select>
          </el-form-item>
          <el-form-item label="生成数量">
            <el-input placeholder="请输入" v-model="count" style="width: 200px;margin-right: 10px;" clearable size="mini" class="box-search"></el-input>
          </el-form-item>
        </el-form>
        <div class="button_box">
          <div class="exchange_bit" @click="confirmGenerate()">确认生成</div>
          <div class="return_bit" @click="generatecloseLog()">返回</div>
        </div>
      </div>
    </el-dialog>
    <!-- 批量更改类型 -->
    <el-dialog :visible="codeTyoeShow" width="600px" title="批量更改类型" :center="true" :before-close="codeTyoecloseLog">
      <div style="height: 200px;">
        <el-form ref="form" label-width="90px">
          <el-form-item label="膜码分类">
            <el-select size="mini" v-model="filmCategoryId" placeholder="膜码分类" style="width: 200px" clearable>
              <el-option v-for="item, index in membranecodeList" :key="index" :label="item.name" :value="item.id"></el-option>
            </el-select>
          </el-form-item>
          <el-form-item label="膜码类型">
            <el-select size="mini" v-model="filmType" placeholder="膜码类型" style="width: 100px" clearable>
              <el-option label="通用膜码" :value="2"></el-option>
              <el-option label="专用膜码" :value="1"></el-option>
            </el-select>
          </el-form-item>
        </el-form>
      </div>
      <div class="button_box">
        <div class="exchange_bit" @click="confirmCodeType()">确认更改</div>
        <div class="return_bit" @click="codeTyoecloseLog()">返回</div>
      </div>
    </el-dialog>
    <!-- 组装装箱码 -->
    <el-dialog :visible="packingShow" width="600px" title="组装装箱码" :center="true" :before-close="codeTyoecloseLog">
      <div style="height: 200px;">
        <el-form ref="form" label-width="90px">
          <el-form-item label="装箱数量">
            <el-input placeholder="请输入" v-model="packCount" style="width: 200px;margin-right: 10px;" clearable size="mini" type="number" class="box-search"></el-input>
          </el-form-item>
        </el-form>
      </div>
      <div class="button_box">
        <div class="exchange_bit" @click="exportCode()">确认生成</div>
        <div class="return_bit" @click="codeTyoecloseLog()">返回</div>
      </div>
    </el-dialog>
    <!-- 关联分销商 -->
    <el-dialog :modal-append-to-body="false" :visible="distributorShow" :before-close="disCancel" width="80%">
      <selectDistributor :multipleChoice="true" :distributorData="[]" ref="selectDistributor" @cancel="cancel" @submit="disSubmit">
      </selectDistributor>
    </el-dialog>
  </div>
</template>
<script>
import { setArr2, parseTime } from "@/utils/common.js";
import selectDistributor from "@/components/distributorList"
import axios from 'axios'
import url from '@/api/allUrl'
export default {
  name: "filmList",
  components: {
    selectDistributor
  },
  data() {
    return {
      packingShow: false,
      loading: false,
      generateShow: false,
      deviceData: [],
      device: {
        page: 1,
        size: 10,
        content: ''
      },
      packCount: 10,
      logTotal: 1,
      codeTyoeShow: false,
      deviceShow: false,
      lookLogShow: false,
      total: 1,
      pageInfo: {
        filmType: '',
        page: 1,
        size: 10,
        content: '',
        filmCategoryId: undefined,
        openFlag: undefined,
        exchangeStatus: undefined,
        contentType: undefined,
        endTime: '',//结束时间
        startTime: '',//开始时间
      },
      membranecodeList: [],
      filmType: '',
      filmCategoryId: '',
      count: 1,
      tableData: [],
      deviceNo: '',
      filmCode: '',
      cuttingCount: 0,
      reCount: 0,
      filmCodes: [],
      distributorShow: false,
    }
  },
  created() {
    this.loading = true;
    this.tableData = [];
    this.getDevicetypeList();
    this.getMembraneCodeList();
  },
  methods: {
    handleSelectionChange(val) { // 当切换页面时的作用
      if (val.length === 0 && this.filmCodes.length !== 0 && !this.isSelect) {
        this.filmCodes.forEach(row1 => {
          this.tableData.forEach(row2 => {
            if (row1 === row2.filmCode) {
              this.$refs.multipleSelect.toggleRowSelection(row2)
            }
          })
        })
        this.isSelect = true
      }
    },
    select(selection, row) {
      this.isSelect = true
      // 单选时调用
      let d = false;
      for (let i = 0; i < this.filmCodes.length; i++) {
        if (this.filmCodes[i] === row.filmCode) {
          this.filmCodes.splice(i, 1);
          d = true;
          break;
        }
      }
      if (!d) {
        this.filmCodes.push(row.filmCode);
        this.filmCodes = setArr2(this.filmCodes);
      }
    },
    selectAll(selection) {
      this.isSelect = true
      // 全选时调用
      if (selection.length === 0) {
        this.tableData.forEach((row) => {
          for (let i = 0; i < this.filmCodes.length; i++) {
            if (this.filmCodes[i] === row.filmCode) {
              this.filmCodes.splice(i, 1);
              break;
            }
          }
        });
      } else {
        selection.forEach((item) => {
          this.filmCodes.push(item.filmCode);
        });
        this.filmCodes = setArr2(this.filmCodes);
      }
    },
    disSubmit(msg) {
      if (msg.length == 0) {
        this.$message({
          type: "info",
          message: "请选择分销商"
        });
      } else {
        this.distributorShow = false;
        this.$api.put(this, url.bindingdistribution, {
          companyName: msg[0].companyName,
          distributorId: msg[0].id,
          distributorName: msg[0].name,
          filmCodes: this.filmCodes
        }).then(res => {
          if (res.success) {
            this.$message.success({
              message: "绑定成功",
              duration: 3 * 1000,
            });
            this.filmCodes = [];
            this.pageInfo.page = 1;
            this.pageInfo.content = '';
            this.getMembraneCodeList();
          }
        })
      }
    },
    unbind(filmCode) {
      if (filmCode != '') {
        this.filmCodes = [filmCode];
      }
      if (this.filmCodes.length == 0) {
        this.$message({
          type: "info",
          message: "请选择膜码"
        });
      } else {
        this.$confirm("是否确认解绑该膜码？", "提示", {
          confirmButtonText: "确定",
          cancelButtonText: "取消",
          type: "warning",
          center: true
        }).then(res => {
          console.log(res);
          this.$api.put(this, url.unbindMembraneCode, { filmCodes: this.filmCodes }).then(res => {
            if (res.success) {
              this.$message.success({
                message: "解绑成功",
                duration: 3 * 1000,
              });
              this.filmCodes = [];
              this.pageInfo.page = 1;
              this.pageInfo.content = '';
              this.loading = true;
              this.getMembraneCodeList();
            }
          })
        }).catch(res => {
          console.log(res);
          this.$message({
            type: "info",
            message: "已取消解绑"
          });
        });
      }
    },
    codeType() {
      if (this.filmCodes.length == 0) {
        this.$message({
          type: "info",
          message: "请选择膜码"
        });
      } else {
        this.codeTyoeShow = true;
      }
    },
    binding(filmCode) {
      if (filmCode != '') {
        this.filmCodes = [filmCode];
        this.distributorShow = true;
      } else if (this.filmCodes.length == 0) {
        this.$message({
          type: "info",
          message: "请选择膜码"
        });
      } else {
        this.distributorShow = true;
      }
    },
    disCancel() {
      this.$refs.selectDistributor.handleCancel()
    },
    cancel() {
      this.distributorShow = false;
    },
    exportCode() {
      if (this.packCount != '' && this.packCount > 0) {
        axios({
          method: 'post',
          url: process.env.VUE_APP_API_URL + url.exportPrintCode,
          data: {
            content: this.pageInfo.content,
            contentType: this.pageInfo.contentType,
            endTime: this.pageInfo.endTime,
            exchangeStatus: this.pageInfo.exchangeStatus,
            filmCategoryId: this.pageInfo.filmCategoryId,
            openFlag: this.pageInfo.openFlag,
            startTime: this.pageInfo.startTime,
            packCount: this.packCount//装箱数量
          },
          responseType: 'arraybuffer',
          headers: {
            'Content-Type': 'application/json',
            'token': localStorage.getItem('token'),
            'tenantNo': 100,
            'value': 100
          }
        }).then(res => {
          const content = res.data
          let blob = new Blob([content], {
            type: "application/ms-excel"
          })
          let url = window.URL.createObjectURL(blob)
          if ('download' in document.createElement('a')) {
            let link = document.createElement('a')
            link.style.display = 'none'
            link.href = url
            link.setAttribute('download', '打印码列表.xls')
            document.body.appendChild(link)
            link.click()
          } else {
            navigator.msSaveBlob(blob, '打印码列表.xls')
          }
        })
      } else {
        this.$message({
          type: "info",
          message: "请输入装箱数量"
        });
      }

    },
    exportMembraneCode() {
      axios({
        method: 'post',
        url: process.env.VUE_APP_API_URL + url.exportMembraneCode,
        data: {
          content: this.pageInfo.content,
          contentType: this.pageInfo.contentType,
          endTime: this.pageInfo.endTime,
          exchangeStatus: this.pageInfo.exchangeStatus,
          filmCategoryId: this.pageInfo.filmCategoryId,
          openFlag: this.pageInfo.openFlag,
          startTime: this.pageInfo.startTime,
        },
        responseType: 'arraybuffer',
        headers: {
          'Content-Type': 'application/json',
          'token': localStorage.getItem('token'),
          'tenantNo': 100,
          'value': 100
        }
      }).then(res => {
        const content = res.data
        let blob = new Blob([content], {
          type: "application/ms-excel"
        })
        let url = window.URL.createObjectURL(blob)
        if ('download' in document.createElement('a')) {
          let link = document.createElement('a')
          link.style.display = 'none'
          link.href = url
          link.setAttribute('download', '膜码列表.xls')
          document.body.appendChild(link)
          link.click()
        } else {
          navigator.msSaveBlob(blob, '膜码列表.xls')
        }
      })
    },
    //确定兑换
    confirmExchange() {
      if (this.deviceNo == '') {
        this.$message({
          type: "info",
          message: "请选择设备"
        });
      } else {
        this.$api.put(this, url.exchangeMembraneCode, {
          deviceNo: this.deviceNo,
          filmCode: this.filmCode,
        }).then(res => {
          if (res.success) {
            this.$message.success({
              message: "兑换成功",
              duration: 3 * 1000,
            });
            this.getMembraneCodeList();
            this.closeLog();
          }
        })
      }
    },
    confirmDevice() {
      this.deviceShow = false;
    },
    returnDevice() {
      this.deviceNo = '';
      this.deviceShow = false;
    },
    closeLog() {
      this.lookLogShow = false;
    },
    //兑换膜码
    exchangeMembraneCode(item) {
      this.lookLogShow = true;
      this.deviceNo = '';
      this.cuttingCount = item.count;
      this.reCount = item.reCount;
      this.filmCode = item.filmCode;
      this.device = {
        page: 1,
        size: 10,
      };
      this.getDeviceList();
    },
    searchDeviceData() {
      this.device.page = 1;
      this.getDeviceList();
    },
    //获取设备列表
    getDeviceList() {
      this.$api.get(this, url.getDeviceList, this.device).then(res => {
        if (res.success) {
          this.deviceData = res.data.list;
          this.logTotal = res.data.total;
        }
      })
    },
    onLogSizeCHange(val) {
      // 分页方法
      this.device.size = val;
      this.getDeviceList();
    },

    onLogCurrentChange(val) {
      // 分页方法
      this.device.page = val;
      this.getDeviceList();
    },
    //删除膜码
    handleDelete(row) {
      this.$confirm("此操作将永久删除该膜码，是否继续？", "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning",
        center: true
      }).then(res => {
        console.log(res);
        this.$api.delete(this, url.deleteMembraneCodeOpen, { id: row.id }).then(res => {
          if (res.success) {
            this.$message.success({
              message: "删除成功",
              duration: 3 * 1000,
            });
            this.pageInfo.page = 1;
            this.pageInfo.content = '';
            this.loading = true;
            this.getMembraneCodeList();
          }
        })
      }).catch(res => {
        console.log(res);
        this.$message({
          type: "info",
          message: "已取消删除"
        });
      });
    },
    //启用禁用膜码
    openDevice(row) {
      var mess = '启用该膜码，是否继续？';
      var openFlag = 1;
      if (row.openFlag == 1) {
        mess = '此操作将禁用该膜码，是否继续？';
        openFlag = 0;
      }
      this.$confirm(mess, "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning",
        center: true
      }).then(res => {
        console.log(res);
        this.$api.put(this, url.MembraneCodeOpen, {
          id: row.id,
          openFlag: openFlag
        }).then(res => {
          if (res.success) {
            this.$message.success({
              message: "操作成功",
              duration: 3 * 1000,
            });
            this.loading = true;
            this.getMembraneCodeList();
          }
        })
      }).catch(res => {
        console.log(res);
        this.$message({
          type: "info",
          message: "已取消"
        });
      });
    },
    //搜索膜码列表
    onSearch() {
      this.loading = true;
      this.pageInfo.page = 1;
      this.getMembraneCodeList();
    },
    //获取膜码列表
    getMembraneCodeList() {
      this.$api.get(this, url.getMembraneCodeList, this.pageInfo).then(res => {
        if (res.success) {
          this.tableData = res.data.list;
          this.total = res.data.total
          this.loading = false;
          setTimeout(() => {
            this.filmCodes.forEach(row1 => {//重新获取数据时，判断哪些选中了
              if (row1 != undefined) {
                this.tableData.forEach(row2 => {
                  if (row1 == row2.filmCode) {
                    this.$refs.multipleSelect.toggleRowSelection(row2);
                  }
                })
              }
            });
          }, 100)
        }
      })
    },
    confirmCodeType() {
      if (this.filmCategoryId == '') {
        this.$message({
          type: "info",
          message: "请选择膜码分类"
        });
      } else if (this.filmType == '') {
        this.$message({
          type: "info",
          message: "请选择膜码类型"
        });
      }
      else {
        this.$api.put(this, url.adjustMembraneCodeType, {
          filmCodes: this.filmCodes,
          filmType: this.filmType,
          filmCategoryId: this.filmCategoryId,
        }).then(res => {
          if (res.success) {
            this.filmCodes = [];
            this.$message.success({
              message: "批量调整成功",
              duration: 3 * 1000,
            });
            this.codeTyoeShow = false;
            this.getMembraneCodeList();
          }
        })
      }
    },
    confirmGenerate() {
      if (this.filmCategoryId == '') {
        this.$message({
          type: "info",
          message: "请选择膜码类型"
        });
      } else if (this.count == '') {
        this.$message({
          type: "info",
          message: "请输入生成数量"
        });
      } else if (!/^\d+(\.\d+)?$/.test(this.count)) {
        this.$message({
          type: "info",
          message: "请输入正确的数字"
        });
      } else {
        this.$api.post(this, url.generateMembraneCode, {
          count: this.count,
          filmCategoryId: this.filmCategoryId,
          filmType: this.filmType,
        }).then(res => {
          if (res.success) {
            this.$message.success({
              message: "生成成功",
              duration: 3 * 1000,
            });
            this.generateShow = false;
            this.getMembraneCodeList();
          }
        })
      }
    },
    addMembraneCode() {
      this.filmCategoryId = '';
      this.count = 1;
      this.generateShow = true;
    },
    // 获取膜码类型
    getDevicetypeList() {
      this.$api.get(this, url.getFilmtypeList, {
        page: 1,
        size: 100,
      }).then(res => {
        if (res.success) {
          this.membranecodeList = res.data.list;
        }
      })
    },
    //删除膜码
    deleteMembranecode() {
      this.$confirm('是否确认删除该膜码？', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning',
        center: true
      }).then(res => {
        console.log(res);
      }).catch(res => {
        console.log(res);
        this.$message({
          type: 'info',
          message: '已取消操作'
        })
      })
    },
    codeTyoecloseLog() {
      this.packingShow = false;
      this.codeTyoeShow = false;
    },
    generatecloseLog() {
      this.generateShow = false;
    },
    addpuls() {
      this.$router.push({ name: 'import_membranecode' })
    },
    onSizeCHange(val) {
      // 分页方法
      this.pageInfo.size = val;
      this.getMembraneCodeList();
    },
    onCurrentChange(val) {
      // 分页方法
      this.pageInfo.page = val;
      this.getMembraneCodeList();
    },
  }, watch: {
    "pageInfo.time": {
      handler(val, oldVal) {
        if (val) {
          this.pageInfo.startTime = parseTime(val[0])
          this.pageInfo.endTime = parseTime(val[1])
        } else {
          this.pageInfo.startTime = undefined
          this.pageInfo.endTime = undefined
        }
        if (val !== oldVal) {
          this.pageInfo.page = 1;
        }
      },
      deep: true
    }
  }
};
</script>

<style rel="stylesheet/scss" lang="scss" scoped>
.el-select,
.el-autocomplete {
  margin-left: 5px;
}

.device_box {
  display: flex;
  /* 在右上角显示 */
  justify-content: flex-end;
  margin-bottom: 20px;
}

.button_box {
  margin: 0 auto;
  display: flex;
  /* 居中显示 */
  align-items: center;
  justify-content: center;

  .exchange_bit {
    width: 100px;
    height: 32px;
    line-height: 32px;
    border-radius: 50px 50px 50px 50px;
    background-color: rgba(252, 126, 27, 1);
    text-align: center;
    color: #ffffff;
    cursor: pointer;
  }

  .return_bit {
    width: 100px;
    height: 32px;
    line-height: 32px;
    border-radius: 50px 50px 50px 50px;
    background-color: rgba(221, 224, 231, 1);
    text-align: center;
    color: #101010;
    margin-left: 10px;
    cursor: pointer;
  }
}

.clean_up {
  color: rgba(20, 123, 209, 1);
  font-size: 14px;
  margin-left: 10px;
  cursor: pointer;
}

.device_data_box {
  height: 100px;
  line-height: 20px;
  border-radius: 10px 10px 10px 10px;
  background-color: rgba(245, 245, 245, 1);
  text-align: center;
  font-family: Roboto;
  margin: 20px 0;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 0 40px;
  color: rgba(59, 59, 59, 1);
  font-size: 14px;
  box-sizing: border-box;
  text-align: left;

  span {
    color: rgba(252, 126, 27, 1);
    font-size: 18px;
    font-weight: 600;
    font-family: SourceHanSansSC-regular;
    margin-left: 10px;
  }
}
</style>
