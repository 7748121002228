<template>
  <div>
    <div class="material_title_box">
      {{checkMsg==0?'新建型号':'编辑型号'}}
    </div>
    <div class="material_linq"></div>
    <div class="material_form_box">
      <el-form :model="formData" :rules="rules" label-width="20%" label-position="right" ref="formData">
        <!-- <el-form-item label="型号分类" prop="name">
          <el-input v-model="formData.name" placeholder="请输入材料名称" />
        </el-form-item> -->
        <el-form-item label="型号中文名称" prop="nameCn">
          <el-input v-model="formData.nameCn" placeholder="请输入型号中文名称" />
        </el-form-item>
        <el-form-item label="型号英文名称" prop="nameEn">
          <el-input v-model="formData.nameEn" placeholder="请输入型号英文名称" />
        </el-form-item>
        <el-form-item label="型号编码" prop="code">
          <el-input v-model="formData.code" placeholder="请输入型号编码" />
        </el-form-item>
        <el-form-item label="入网型号" prop="internetType">
          <el-input v-model="formData.internetType" placeholder="请输入入网型号" />
        </el-form-item>
        <el-form-item label="状态" style="margin-bottom: 0px;">
          <el-radio-group v-model="formData.status" size="mini">
            <el-radio :label="0">启用</el-radio>
            <el-radio :label="1">禁用</el-radio>
          </el-radio-group>
        </el-form-item>
      </el-form>
    </div>
    <div class="button_box">
      <div class="exchange_bit" @click="submitData()">保存</div>
      <div class="return_bit" @click="returnList()">返回</div>
    </div>
  </div>
</template>
<script>
import api from "@/api/allUrl";
export default {
  props: ['categoryId', 'checkMsg', 'materialListId'],
  data() {
    return {
      formData: {
        categoryId: '',
        code: '',
        internetType: '',
        nameCn: '',
        nameEn: '',
        status: 0
      },
      rules: {
        // code: [
        //   { required: true, message: "请输入型号编码", trigger: "blur" },
        // ],
        // internetType: [
        //   { required: true, message: "请输入入网型号", trigger: "blur" },
        // ],
        nameCn: [
          { required: true, message: "请输入型号中文名称", trigger: "blur" },
        ]
        // ,
        // nameEn: [
        //   { required: true, message: "请输入型号英语名称", trigger: "blur" },
        // ]
      },
    }
  },
  created() {
    if (this.checkMsg == 1) {
      this.$api.get(this, api.getModellistdetails, {
        id: this.materialListId
      }).then(res => {
        this.formData = res.data;
      });
    }
  },
  methods: {
    returnList() {
      this.$emit("returnList");
    },
    submitData() {
      this.$refs['formData'].validate(valid => {
        this.formData.categoryId = this.categoryId;
        if (valid) {
          if (this.checkMsg == 0) {
            this.$api.post(this, api.addModellist, this.formData).then(res => {
              if (res.success) {
                this.$message({
                  message: "新增成功",
                });
                this.returnList();
              }
            });
          } else {
            this.$api.put(this, api.updateModeldetails, this.formData).then(res => {
              if (res.success) {
                this.$message({
                  message: "修改成功",
                });
                this.returnList();
              }
            });
          }
        }
      })
    },
  }
};
</script>

<style rel="stylesheet/scss" lang="scss" scoped>
</style>
