<template>
  <div>
    <div class="page_header_title_box">
      店铺列表
    </div>
    <div style="padding: 70px 20px 20px 20px;">
      <div class="content_box">
        <div class="heading_box">
          <div class="search_for_box">
            <el-button class="mini_add_btn" @click="handlePlatform()">新增店铺</el-button>
            <el-button class="mini_add_btn" @click="importShop()">导入店铺</el-button>
          </div>
          <div class="search_for_box">
            <el-select class="mini_select_bit" size="mini" v-model="pageInfo.openFlag" placeholder="状态" style="width: 100px;margin-right: 5px;" clearable>
              <el-option label="启用" :value="1"></el-option>
              <el-option label="禁用" :value="0"></el-option>
            </el-select>
            <el-autocomplete clearable size="mini" style="width: 160px;margin-right: 5px;" v-model="distributorName" :fetch-suggestions="querySearchAsync" placeholder="请输入分销商名称" @select="handleSelect" @clear="clearSelect"></el-autocomplete>
            <el-input style="width: 230px;" placeholder="门店名称/门店编码/手机号" v-model="pageInfo.content" @keyup.enter.native="onSearch()">
              <el-button slot="append" icon="el-icon-search" @click.prevent="onSearch()"></el-button>
            </el-input>
          </div>
        </div>
        <el-table :data="tableData">
          <el-table-column label="分销商名称" align="center" prop="distributorName"></el-table-column>
          <!-- <el-table-column label="分销商公司" align="center" prop="shopName"></el-table-column> -->
          <el-table-column label="门店名称" align="center" prop="shopName"></el-table-column>
          <el-table-column label="门店编码" align="center" prop="shopCode"></el-table-column>
          <el-table-column label="手机号" align="center" prop="phone"></el-table-column>
          <el-table-column align="center" label="状态" prop="openFlag" show-overflow-tooltip>
            <template slot-scope="scope">
              <div class="a">{{ getStatus(scope.row.openFlag) }}</div>
            </template>
          </el-table-column>
          <!-- <el-table-column align="center" label="微信访问URL" :min-width="200" prop="url" show-overflow-tooltip></el-table-column> -->
          <el-table-column align="center" label="店铺二维码" width="150">
            <template slot-scope="scope">
              <el-image v-if="scope.row.qrUrl" style="
                text-align: center;
                width: 120px;
                height: 120px;
                padding-right: 0px;
              " fit="contain" :src="scope.row.qrUrl + '?x-oss-process=image/resize,h_120,l_120'" :preview-src-list="[scope.row.qrUrl]">
              </el-image>
              <span v-else>/</span>
            </template>
          </el-table-column>
          <el-table-column align="center" label="店铺二维码（三方规则）" width="185">
            <template slot-scope="scope">
              <el-image v-if="scope.row.thirdQrUrl" style="
                text-align: center;
                width: 120px;
                height: 120px;
                padding-right: 0px;
              " fit="contain" :src="scope.row.thirdQrUrl + '?x-oss-process=image/resize,h_120,l_120'" :preview-src-list="[scope.row.thirdQrUrl]">
              </el-image>
              <span v-else>/</span>
            </template>
          </el-table-column>
          <el-table-column label="操作" align="center" :width="300">
            <template slot-scope="scope">
              <el-button @click="handleEdit(scope.row)">编辑</el-button>
              <el-button :type="scope.row.openFlag==1?'warning':'success'" @click="handleChangeStatus(scope.row)">{{ getfStatus(scope.row.openFlag)}}</el-button>
              <el-button type="danger" @click="handleDelete(scope.row)">删除</el-button>
            </template>
          </el-table-column>
        </el-table>
        <el-pagination :total="total" :page="pageInfo.pageNo" @size-change="onSizeCHange" @current-change="onCurrentChange">
        </el-pagination>
      </div>
    </div>
  </div>
</template>
<script>
import api from '@/api/allUrl'
export default {
  name: "shopList",
  data() {
    return {
      tableData: [],
      pageInfo: {
        content: '',
        page: 1,
        size: 10,
        openFlag: '',
        distributorId: ''
      },
      distributorName: '',
      total: 0,
    }
  },
  created() {
    this.getModellist();
  },
  methods: {
    importShop() {
      this.$router.push({
        name: "import_shop",
      });
    },
    handlePlatform() {
      this.$router.push({
        name: "storeDetail",
      });
    },
    // 编辑
    handleEdit(row) {
      var query = { id: row.id, checkMsg: 1 };
      this.$router.push({
        name: "storeDetail",
        query: query,
      });
    },
    clearSelect() {
      this.pageInfo.distributorId = '';
    },
    onSearch() {
      this.pageInfo.page = 1;
      this.getModellist();
    },
    handleSelect(item) {
      this.pageInfo.distributorId = item.id;
    },
    querySearchAsync(queryString, cb) {
      let pageInfo = {
        page: 1,
        size: 10,
        freezeStatus: 1,
        contentType: 1,
        content: queryString
      }
      this.$api.get(this, api.getDistributorNPoList, pageInfo).then(res => {
        if (res.success) {
          var restaurants = []
          res.data.list.forEach(item => {
            restaurants.push({
              "value": item.name,
              "id": item.id,
            })
          })
        }
        cb(restaurants)
      })
    },
    // 删除
    handleDelete(row) {
      if (row.id) {
        this.$confirm("此操作将删除已选数据，是否继续？", "提示", {
          confirmButtonText: "确定",
          cancelButtonText: "取消",
          type: "warning",
          center: true,
        }).then(() => {
          this.$api.delete(this, api.deleteStore, {
            id: row.id
          }).then(res => {
            if (res.success) {
              this.$message({
                type: 'success',
                message: '删除成功'
              })
              this.getModellist();
            } else {
              this.$message({
                type: 'error',
                message: '删除失败'
              }).catch((err) => {
                console.log(err);
              });
            }
          });
        })
      }
    },
    // 更改状态
    handleChangeStatus(row) {
      let status = row.openFlag === 1 ? 0 : 1;
      this.$api.put(this, api.storeActivationDeactivation, {
        id: row.id,
        openFlag: status
      }).then(res => {
        if (res.success) {
          this.$message.success('更新成功！')
          this.getModellist();
        }
      });
    },
    // 获取相反状态
    getfStatus(val) {
      if (Number(val) === 0) {
        return "开启";
      } else if (Number(val) === 1) {
        return "停用";
      }
    },
    getModellist() {
      this.$api.get(this, api.getShopList, this.pageInfo).then(res => {
        if (res.success) {
          this.tableData = res.data.list;
          this.total = res.data.total;
        }
      });
    },
    // 获取状态
    getStatus(val) {
      if (Number(val) === 1) {
        return "开启";
      } else if (Number(val) === 0) {
        return "停用";
      }
    },
  }
};
</script>

<style rel="stylesheet/scss" lang="scss" scoped>
</style>
